import * as React from "react";

function SvgTobacco(props) {
  return (
    <svg
      className="icon icon__questionnaire"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="-5 -50 80 110"
    >
      <g >
        <path d="M1.70455 14.3337L70.4315 14.3337C71.3729 14.3337 72.1361 13.5705 72.1361 12.6291L72.1361 1.70455C72.1361 0.763151 71.3729 0 70.4315 0L1.70455 0C0.763151 0 0 0.763151 0 1.70455L0 12.6291C0 13.5705 0.763151 14.3337 1.70455 14.3337L1.70455 14.3337L1.70455 14.3337ZM19.2996 10.9246L19.2996 3.40909L60.7821 3.40909L60.7821 10.9246L19.2996 10.9246L19.2996 10.9246ZM68.727 10.9246L64.1907 10.9246L64.1907 3.40909L68.727 3.40909L68.727 10.9246L68.727 10.9246ZM3.40909 3.40893L15.8905 3.40893L15.8905 10.9244L3.40909 10.9244L3.40909 3.40893L3.40909 3.40893Z" transform="translate(0 24.729853)"   fill-rule="evenodd" stroke="none" />
        <path d="M3.40909 1.70455C3.40909 0.763152 2.64594 0 1.70455 0C0.763151 0 0 0.763152 0 1.70455C0 4.66565 1.32676 6.22469 2.39287 7.47722C3.31007 8.55496 3.97268 9.33363 3.97268 11.0403C3.97268 11.9817 4.73583 12.7449 5.67722 12.7449C6.61861 12.7449 7.38177 11.9817 7.38177 11.0403C7.38177 8.07924 6.05501 6.52035 4.9889 5.26767C4.0717 4.19024 3.40909 3.41157 3.40909 1.70455L3.40909 1.70455L3.40909 1.70455Z" transform="translate(67.58724 9.335482)"   fill-rule="evenodd" stroke="none" />
        <path d="M3.9733 20.376C3.9733 21.3174 4.73645 22.0805 5.67784 22.0805C6.61925 22.0805 7.38239 21.3174 7.38239 20.376C7.38239 17.4149 6.05563 15.856 4.98952 14.6033C4.07232 13.5257 3.40894 12.7471 3.40894 11.0402C3.40894 9.33331 4.07154 8.5548 4.98952 7.47722C6.05563 6.22453 7.38239 4.6655 7.38239 1.70455C7.38239 0.763151 6.61925 0 5.67784 0C4.73645 0 3.9733 0.763151 3.9733 1.70455C3.9733 3.41126 3.31069 4.18993 2.39272 5.26766C1.32676 6.5202 0 8.07908 0 11.0402C0 14.0013 1.32676 15.5603 2.39287 16.8129C3.31069 17.8906 3.9733 18.6693 3.9733 20.376L3.9733 20.376L3.9733 20.376Z" transform="translate(61.38827 0)"   fill-rule="evenodd" stroke="none" />
        <path d="M3.97345 11.0403C3.97345 11.9817 4.73661 12.7449 5.678 12.7449C6.61939 12.7449 7.38254 11.9817 7.38254 11.0403C7.38254 8.07924 6.05578 6.52035 4.98967 5.26767C4.07247 4.19008 3.40909 3.41141 3.40909 1.70455C3.40909 0.763152 2.64594 0 1.70455 0C0.763151 0 0 0.763152 0 1.70455C0 4.66565 1.32676 6.22469 2.39287 7.47722C3.31085 8.55496 3.97345 9.33363 3.97345 11.0403L3.97345 11.0403L3.97345 11.0403Z" transform="translate(55.189774 9.335638)"   fill-rule="evenodd" stroke="none" />
      </g>
    </svg>
  );
}

export default SvgTobacco;
