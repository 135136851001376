import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

const HeaderBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '4px',
}));

const ReportNameFormat = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontWeight: 1,
}));

const CustomerNameFormat = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export default function ReportHeader(props) {
  const { report_name, customerName } = props;
  return (
    <HeaderBox>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        padding={1}
      >
        <ReportNameFormat variant="h6">{report_name}</ReportNameFormat>
        <CustomerNameFormat variant="h6">{customerName}</CustomerNameFormat>
      </Stack>
    </HeaderBox>
  );
}
