import * as React from 'react';
import ReactEcharts from 'echarts-for-react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';

const borderWidth = 1;

//Chart style
const style = {
  height: '300px',
  width: '100%',
};

const CreateOptions = (params) => {
  let options = {
    backgroundColor: 'rgb(255, 255, 255)',
    title: {},
    legend: {
      type: 'scroll',

      bottom: 0,
      data: [],
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}',
      position: 'bottom',
    },
    grid: {
      left: '50px',
      top: '10%',
      containLabel: true,
    },
    xAxis: {
      name: 'Relative Consistency Level',
      min: 0,
      max: 100,
      nameLocation: 'middle',
      nameGap: 25,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitNumber: '2',
      splitLine: {
        lineStyle: {
          color: 'black',
        },
      },
    },
    yAxis: {
      name: 'Relative Importance Level',
      min: 0,
      max: 100,
      nameLocation: 'middle',
      nameGap: 25,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitNumber: '2',
      splitLine: {
        lineStyle: {
          color: 'black',
        },
      },
    },
    series: [],
  };

  const resolve_bubblesize = (bubble_size) => {
    switch (bubble_size) {
      case 'Size1':
        return 40;
      case 'Size2':
        return 25;
      case 'Size3':
        return 15;
      default:
        throw Error('Undefined bubblesize');
    }
  };

  const format_category = (category) => {
    switch (category) {
      case 'community':
        return 'Community';
      case 'emissions':
        return 'Emissions';
      case 'environmental_innovations':
        return 'Environmental Innovations';
      case 'management':
        return 'Management';
      case 'product_responsibility':
        return 'Product Responsibility';
      case 'resource_use':
        return 'Resource Use';
      case 'shareholders':
        return 'Shareholders';
      case 'sustainable_strategy':
        return 'Sustainable Strategy';
      case 'working_conditions':
        return 'Working Conditions';
      default:
        throw Error('unknown category');
    }
  };

  params.props.forEach(
    ({ category, x_coord, y_coord, color, border_color, bubble_size }) => {
      category = format_category(category);

      let serie = [
        {
          name: category,

          data: [[x_coord, y_coord, category, category]],
          type: 'scatter',
          symbolSize: resolve_bubblesize(bubble_size),
          emphasis: {
            focus: 'series',
            label: {
              show: false,
            },
          },
          itemStyle: {
            color: color,
            borderColor: border_color,
            borderWidth: borderWidth,
          },
        },
      ];
      options.legend.data = [...options.legend.data, category];
      options.series = [...options.series, ...serie];
    }
  );
  return options;
};

const AnalyticsCard = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{ borderRadius: '4px', height: height, border: 1, width: '100%' }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 20 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent
        sx={{
          bgcolor: 'white',
          width: '100%',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default function BubbleChart(props) {
  const { ...other } = props;

  const options = CreateOptions(other);

  return (
    <AnalyticsCard header="SUSTAINABILITY MAP" height={350} fullWidth>
      <Box width="100%" maxWidth="400px" mx={'auto'}>
        <ReactEcharts option={options} style={style} />
      </Box>
    </AnalyticsCard>
  );
}
