import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const HeaderBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '4px',
}));

const CompanySnapshotFormat = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontWeight: 1,
}));

const CustomerNameFormat = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export default function ReportHeader(props) {
  const { companySnapshot, customerName } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <HeaderBox>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        padding={1}
      >
        <CustomWidthTooltip
          open={showTooltip}
          onOpen={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
          title={
            <Box>
              <Typography fontSize={12} color={'white'}>
                {
                  'Data to be available soon'
                }
              </Typography>
            </Box>
          }
        >
          <CompanySnapshotFormat
            variant="h6"
            onClick={() => setShowTooltip(!showTooltip)}
          >
             {companySnapshot} 
          
          </CompanySnapshotFormat>
        </CustomWidthTooltip>
        <CustomerNameFormat variant="h6">{customerName}</CustomerNameFormat>
      </Stack>
    </HeaderBox>
  );
}
