import * as React from 'react';
import GlobalPositioning from './partials/GlobalPositioning';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import ProfileCard from './partials/ProfileCard';
import ReportHeader from './partials/ReportHeader';

import BubbleChart from './partials/BubbleChart';

import Exclusions from './partials/Exclusions';
import ESGPreferences from './partials/ESGPreferencesImpl';
import MifidReport from './partials/MifidReport';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import { useReactToPrint } from 'react-to-print';
import PDFClient from './PDFClients';

const PortfolioReportButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 150,
  borderRadius: '4px',
}));

export default function InvestorProfileResults(props) {
  const { mifid, ...analytics } = props;
  
  const [showPDFView, setShowPDFView] = React.useState(false);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setShowPDFView(false);
    },
  });

  if (Object.keys(analytics.props).length === 0) return '';

  const myTimeoutFunction = () => {
    handlePrint();
  };

  // Set a timeout of 3 seconds for PDF to load
  const handleTimeout = () => {
    setShowPDFView(true);
    setTimeout(myTimeoutFunction, 3000);

    // clearTimeout(timeout);
  };

  return (
    <Grid container>
      <Grid
        container
        spacing={0.5}
        sx={{
          display: !showPDFView ? 'flex' : 'none',
        }}
      >
        <Grid item xs={12}>
          <ReportHeader
            report_name={'INVESTOR VALUES PROFILE'}
            customerName={analytics.props.customer_name}
          />
        </Grid>
        <Grid item xs={12}>
          <MifidReport {...mifid} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0.5}
        marginTop={0.2}
        sx={{
          display: !showPDFView ? 'flex' : 'none',
        }}
      >
        <Grid item xs={12} md={5}>
          <GlobalPositioning {...analytics.props.thematics} />
        </Grid>

        {/* ESG Preferences and Exclusions */}
        <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>
          <Stack
            container
            display={'flex'}
            gap="4px"
            spacing={0.5}
            direction={'column'}
          >
            <ESGPreferences {...analytics.props.wfesg[0]} />
            <Exclusions {...analytics.props.exclusions} />
          </Stack>
        </Grid>

        {/* Profile Card */}
        <Grid item xs={12} md={5} sx={{ order: { xs: 1, md: 2 } }}>
          <ProfileCard {...analytics.props.thematics} />
        </Grid>

        {/* Bubble Chart */}
        <Grid item xs={12} md={7} sx={{ order: { xs: 3, md: 3 } }}>
          <BubbleChart props={analytics.props.bubblechart} />
        </Grid>
      </Grid>

      <Box
        mt={2}
        mx={'auto'}
        display={'flex'}
        gap={2}
        sx={{
          display: !showPDFView ? 'flex' : 'none',
        }}
      >
        <Box>
          <PortfolioReportButton variant="contained" onClick={handleTimeout}>
            Export as PDF
          </PortfolioReportButton>
        </Box>
        <Box onClick={props.close}>
          <PortfolioReportButton variant="contained">
            Close
          </PortfolioReportButton>
        </Box>
      </Box>
      <PDFClient
        handlePrint={handlePrint}
        mifid={mifid}
        analytics={analytics}
        showPDFView={showPDFView}
        setShowPDFView={setShowPDFView}
        ref={componentRef}
        {...props}
      />
    </Grid>
  );
}
