import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function StrategiesSvgIcon(props){
    return (
        <SvgIcon {...props} >
        <path d="M62.6667 72.6667H16V66H62.6667V72.6667ZM42.6667 6C38.5 6 34.6 8.06667 32.3 11.5333L22.6667 26L29.3333 32.6667L36.2 28.1C37.6667 27.0667 39.8 27.4667 40.8333 29C40.9 29.1 41 29.2 41 29.3333C42 31.3 41.6333 33.6667 40.0667 35.2333L24.0667 51.2333C22.2333 53.1 22.2333 56.1 24.1 57.9333C24.9667 58.8 26.1667 59.3333 27.4 59.3333H56V19.3333C56 15.7971 54.5952 12.4057 52.0948 9.90524C49.5943 7.40476 46.2029 6 42.6667 6Z" />
        </SvgIcon>
    );
}

export default StrategiesSvgIcon;

