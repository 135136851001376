import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

export default function PortfolioReportCardDocTransparency(props) {
  const { headerText, children, ...other } = props;

  return (
    <Box
      flex={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
    >
      <Card
        sx={{
          borderRadius: '8px',
          boxSizing: 'border-box',

          border: '2px solid #003865',
        }}
        {...other}
      >
        <CardHeader
          sx={{ bgcolor: 'primary.main', textAlign: 'center', height: '30px' }}
          subheader={
            <Typography variant="h7" fontWeight={1} color="white">
              {headerText}
            </Typography>
          }
        />

        <CardContent
          sx={{
            bgcolor: 'white',
            padding: 0,
            flex: 1,
          }}
        >
          {children}
        </CardContent>
      </Card>
    </Box>
  );
}
