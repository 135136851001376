import React,{useContext,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './components/Login';

import reportWebVitals from './reportWebVitals';

import {ThemeProvider } from '@mui/material/styles';
import { theme } from './components/Themes';
import CssBaseline from '@mui/material/CssBaseline';
import ProtectedLandingPage from './components/ProtectedLandingPage'
import { ApolloClient, InMemoryCache, ApolloLink,ApolloProvider,HttpLink,concat } from '@apollo/client';
import Context from './context';
import reducer from './reducer';
import {GRAPHQL_URL} from './config';



const httpLink=new HttpLink({uri:GRAPHQL_URL,
  options: {
    reconnect: true
  }
}); 

const authMiddleware = new ApolloLink((operation, forward) => {
    
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers, 
    }
  }));

  return forward(operation);
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});



const Root=()=>{
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);


  return (
    <Router>
      <ApolloProvider client={client}>
        <Context.Provider value={{state,dispatch}}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <Routes>
                <Route path='/' element={<App/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/home' element={state.isAuth?<ProtectedLandingPage/>:<div>404</div>}/>
              </Routes>
          </ThemeProvider>
        </Context.Provider>
      </ApolloProvider>
    </Router>
  )
}

ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
