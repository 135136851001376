import * as React from 'react';
import Box from '@mui/material/Box';

const NorsiaBigBox = (props) => {
  const { children } = props;

  return (
    <Box sx={{ border: 2, borderRadius: 1, borderColor: 'primary.main' }}>
      {children}
    </Box>
  );
};

const NorsiaContentBox = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: 'primary.main',
        height: 150,
        paddingLeft: 2,
        overflow: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

const NorsiaHeaderBox = (props) => {
  const { children, ...other } = props;

  return (
    <Box borderBottom={2} borderColor={'secondary.main'} {...other}>
      {children}
    </Box>
  );
};

const NorsiaHeaderBoxFilled = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        marginTop: 2,
        borderTopRightRadius: 8,
        backgroundColor: 'primary.main',
        width: { xs: '90%', sm: '100%' },
      }}
    >
      {children}
    </Box>
  );
};

export {
  NorsiaBigBox,
  NorsiaHeaderBox,
  NorsiaContentBox,
  NorsiaHeaderBoxFilled,
};
