import * as React from 'react';
import ReactEcharts from 'echarts-for-react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';

//Chart style
const style = {
  height: '350px',
  width: '100%',
  padding: '15px',
  pointerEvents: 'none',
};

const get_below_avg = (avg_all, min_all) => {
  return avg_all - min_all;
};

const get_above_avg = (max_all, avg_all, min_all) => {
  return max_all - avg_all;
};

const CreateOptions = (props) => {
  const {
    env_min,
    env_max,
    env_avg,
    env_inv,
    soc_min,
    soc_max,
    soc_avg,
    soc_inv,
    gvn_min,
    gvn_max,
    gvn_avg,
    gvn_inv,
  } = props;

  return {
    legend: {
      data: ['Investor', 'Below Average', 'Above Average'],
    },

    grid: {
      left: '5px',
      right: '5%',
      bottom: '55%',
      top: '10%',
      containLabel: true,
    },

    xAxis: {
      type: 'value',
      min: `${Math.min(...[env_min, soc_min, gvn_min]) - 5}`,

      splitLine: {
        show: false,
      },
      axisLabel: {
        show: true, // Ensure axis labels are shown
      },
      axisPointer: {
        show: true, // Show axis pointer
      },
    },

    yAxis: {
      type: 'category',
      inverse: false,
      data: ['Governance', 'Social', 'Environment'],
    },

    series: [
      {
        name: 'Minimum',
        type: 'bar',
        stack: 'total',
        barWidth: '35%',
        itemStyle: {
          normal: {
            //barBorderWidth: 2,
            //barBorderColor: '#FFFFFF',
          },
        },
        emphasis: {
          disabled: true,
        },
        color: 'rgb(255,255,255,0)',
        data: [gvn_min, soc_min, env_min],
      },

      {
        name: 'Below Average',
        type: 'bar',
        stack: 'total',
        barWidth: '35%',
        itemStyle: {
          normal: {
            //barBorderWidth: 2,
            //barBorderColor: '#FFFFFF',
            borderRadius: [5, 0, 0, 5],
          },
        },
        emphasis: {
          disabled: true,
        },
        color: 'rgb(185,205,229)',
        data: [
          get_below_avg(gvn_avg, gvn_min),
          get_below_avg(soc_avg, soc_min),
          get_below_avg(env_avg, env_min),
        ],
      },

      {
        name: 'Above Average',
        type: 'bar',
        stack: 'total',
        barWidth: '35%',
        itemStyle: {
          normal: {
            // barBorderWidth: 2,
            //barBorderColor: '#FFFFFF',
            borderRadius: [0, 5, 5, 0],
          },
        },
        emphasis: {
          disabled: true,
        },
        color: 'rgb(0,56,101)',
        data: [
          get_above_avg(gvn_max, gvn_avg, gvn_min),
          get_above_avg(soc_max, soc_avg, soc_min),
          get_above_avg(env_max, env_avg, env_min),
        ],
      },

      {
        name: 'Investor',
        type: 'line',
        symbol: 'circle',
        symbolSize: '12',
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          borderColor: 'rgba(243, 146, 0)',
          borderWidth: '3',
        },
        color: 'rgb(255,255,255,1)',
        emphasis: {
          disabled: true,
        },
        data: [gvn_inv, soc_inv, env_inv],
      },
    ],
  };
};

const AnalyticsCard = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{ borderRadius: '4px', height: height, border: 1, width: 1 }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 20 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent sx={{ bgcolor: 'white', padding: 0 }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default function ESGPreferences(props) {
  const { ...data } = props;

  const options = CreateOptions(data);

  return (
    <AnalyticsCard header="ESG PREFERENCES" height={230} fullWidth>
      <Box width="100%" maxWidth="400px" mx={'auto'}>
        <ReactEcharts option={options} style={style} />
      </Box>
    </AnalyticsCard>
  );
}
