import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function CashLockSvgIcon(props){
    return (
    <SvgIcon {...props}>
    <path d="M72.6668 50C74.6668 50 76.6668 52 76.6668 54.3333V66C76.6668 68 74.6668 70 72.3334 70H54.0001C52.0001 70 50.0001 68 50.0001 65.6667V54C50.0001 52 52.0001 50 54.0001 50V45C54.0001 40.3333 58.6668 36.6667 63.3334 36.6667C68.0001 36.6667 72.6668 40.3333 72.6668 45V50ZM68.3334 50V45C68.3334 42.3333 66.0001 40.6667 63.3334 40.6667C60.6668 40.6667 58.3334 42.3333 58.3334 45V50H68.3334ZM6.66675 20H66.6668V30.3333L63.3334 30C60.6001 30 58.0334 30.6667 55.7668 31.8C54.2668 30.6 53.3334 28.7333 53.3334 26.6667H20.0001C20.0001 30.3667 17.0334 33.3333 13.3334 33.3333V46.6667C15.1015 46.6667 16.7972 47.369 18.0475 48.6193C19.2977 49.8695 20.0001 51.5652 20.0001 53.3333H43.4668L43.3334 55V60H6.66675V20ZM36.6668 30C42.2001 30 46.6668 34.4667 46.6668 40C46.6668 45.5333 42.2001 50 36.6668 50C31.1334 50 26.6667 45.5333 26.6667 40C26.6667 34.4667 31.1334 30 36.6668 30Z"/>
    </SvgIcon>);
}

export default CashLockSvgIcon;