import * as React from 'react';
import ReactEcharts from 'echarts-for-react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';

const borderWidth = 1;

//Chart style
const style = {
  height: '330px',
  width: '100%',
};

const CreateOptions = (params) => {
  let options = {
    backgroundColor: 'rgb(255, 255, 255)',
    title: {},
    legend: {
      type: 'plain',
      orient: 'vertical',
      right: '50px',
      top: '10%',
      data: [],
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}',
      position: 'bottom',
    },
    grid: {
      left: '50px',
      right: '250px',
      bottom: '35px',
      top: '30px',
      containLabel: true,
    },
    xAxis: {
      name: 'Relative Consistency Level',
      min: 0,
      max: 100,
      nameLocation: 'middle',
      nameGap: 25,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitNumber: '2',
      splitLine: {
        lineStyle: {
          color: 'black',
        },
      },
    },
    yAxis: {
      name: 'Relative Importance Level',
      min: 0,
      max: 100,
      nameLocation: 'middle',
      nameGap: 25,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitNumber: '2',
      splitLine: {
        lineStyle: {
          color: 'black',
        },
      },
    },
    series: [],
  };

  const resolve_bubblesize = (bubble_size) => {
    switch (bubble_size) {
      case 'Size1':
        return 40;
      case 'Size2':
        return 25;
      case 'Size3':
        return 15;
      default:
        throw Error('Undefined bubblesize');
    }
  };

  const format_category = (category) => {
    switch (category) {
      case 'emissions':
        return 'Emissions';
      case 'resource_use':
        return 'Resource Use';
      case 'environmental_innovations':
        return 'Environmental Innovations';
      case 'working_conditions':
        return 'Working Conditions';
      case 'community':
        return 'Community';
      case 'product_responsibility':
        return 'Product Responsibility';
      case 'management':
        return 'Management';
      case 'shareholders':
        return 'Shareholders';
      case 'sustainable_strategy':
        return 'Sustainable Strategy';

      default:
        throw Error('unknown category');
    }
  };

  // Custom order mapping for the categories
  const categoryOrder = {
    emissions: 1,
    resource_use: 2,
    environmental_innovations: 3,
    working_conditions: 4,
    community: 5,
    product_responsibility: 6,
    management: 7,
    shareholders: 8,
    sustainable_strategy: 9,
  };

  const sortLegendItems = (a, b) => {
    return categoryOrder[a.name] - categoryOrder[b.name];
  };

  const legendItems = params.props.map(({ category, color }) => ({
    name: format_category(category),
    color,
  }));

  // Sort the legend items based on custom order
  legendItems.sort(sortLegendItems);

  legendItems.forEach(({ name }) => {
    // Add each legend item to the legend data array
    options.legend.data.push(name);
  });

  /*params.props.sort(
    (a, b) => categoryOrder[a.category] - categoryOrder[b.category]
  );*/

  params.props.forEach(
    ({ category, x_coord, y_coord, color, border_color, bubble_size }) => {
      category = format_category(category);

      let serie = [
        {
          name: category,

          data: [[x_coord, y_coord, category, category]],
          type: 'scatter',
          symbolSize: resolve_bubblesize(bubble_size),
          emphasis: {
            focus: 'series',
            label: {
              show: false,
            },
          },
          itemStyle: {
            color: color,
            borderColor: border_color,
            borderWidth: borderWidth,
          },
        },
      ];
      options.legend.data = [...options.legend.data, category];
      options.series = [...options.series, ...serie];
    }
  );
  return options;
};

const AnalyticsCard = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{
        borderRadius: '8px',
        height: height,
        border: '2px solid #003865',
        width: '100%',
        padding: 0,
      }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 20 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent
        sx={{
          bgcolor: 'white',
          width: '100%',
          height: '100%',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default function BubbleChartDoc(props) {
  const { ...other } = props;
  
  const options = CreateOptions(other);

  return (
    <AnalyticsCard header="SUSTAINABILITY MAP" height={380} fullWidth>
      <Box width="100%" height={'100%'} mx={'auto'} zIndex={99}>
        <ReactEcharts option={options} style={style} />
      </Box>
    </AnalyticsCard>
  );
}
