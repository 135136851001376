import * as React from 'react';

function SvgAnimalTesting(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 80"
      {...props}
    >
      <path d="M53.211 36.558l-.419-.152a4.98 4.98 0 00-7.873-5.322 5.117 5.117 0 00-2.109.055 4.995 4.995 0 00-3.41 6.809l-1.4.873L36.684 38A15.852 15.852 0 0033 36.321V34a2 2 0 002-2v-2h1a1 1 0 001-1V13a1 1 0 00-1-1h-1v-2a3 3 0 000-6h-6a3 3 0 000 6v2h-1a1 1 0 00-1 1v16a1 1 0 001 1h1v2a2 2 0 002 2v1.827a14.312 14.312 0 00-5.735.16A14.617 14.617 0 0014 50.234c0 .258.018.512.037.766H8a4 4 0 01-4-4v-1a4 4 0 014-4h2a6 6 0 000-12H3a1 1 0 000 2h7a4 4 0 010 8H8a6.006 6.006 0 00-6 6v1a6.006 6.006 0 006 6h6.389a10.033 10.033 0 002.567 4.37l2.337 2.337A1 1 0 0020 60h29a1 1 0 001-1 5.008 5.008 0 00-4.749-4.994l3.405-6.127 7-1.555A2.982 2.982 0 0058 43.4a7.3 7.3 0 00-4.789-6.842zM35 28h-6v-2h2v-2h-2v-2h2v-2h-2v-2h6zM28 7a1 1 0 011-1h6a1 1 0 010 2h-6a1 1 0 01-1-1zm3 3h2v2h-2zm-1 4h5v2h-6v-2zm3 16v2h-2v-2zm18 5a3.007 3.007 0 01-.095.72l-2.028-.738a4.99 4.99 0 00-1.738-2.844A2.97 2.97 0 0148 32a3 3 0 013 3zm4.217 9.372l-7.434 1.652a1 1 0 00-.657.49l-4.444 8A1 1 0 0043.556 56H45a3.006 3.006 0 012.829 2h-8.583l1.73-7.783-1.952-.434L37.2 58h-6.3a5.009 5.009 0 00-4.9-4h-3.268a8.985 8.985 0 00-.346-8.633l-.529-.881-1.714 1.028.528.882a6.993 6.993 0 010 7.209l-.528.881A1 1 0 0021 56h5a3 3 0 012.816 2h-8.4l-2.046-2.044A8.041 8.041 0 0116 50.234a12.623 12.623 0 019.731-12.3 13.084 13.084 0 019.893 1.762l1.846 1.154a1 1 0 001.06 0l2.007-1.254a5.011 5.011 0 002.213 1.248l.5-1.936a3.01 3.01 0 01-1.61-1.054l-.025.019a.986.986 0 00-.067-.153 3 3 0 01-.442-2.525 2.95 2.95 0 012.158-2.107 2.991 2.991 0 013.71 2.7 1 1 0 00.656.869l4.9 1.781A5.292 5.292 0 0156 43.4a.994.994 0 01-.783.972z" />
      <path d="M48 40h2v2h-2z" />
    </svg>
  );
}

export default SvgAnimalTesting;
