import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import { Slider } from '@mui/material';
import { Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const Widget = styled(Avatar)(({ theme }) => ({
  background: 'white',
  border: '3px solid',
  borderColor: theme.palette.primary.main,
  height: 30,
  width: 30,
}));

const WidgetText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 10,
  fontWeight: 'bold',
  margin: 'auto',
}));

const MetricText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const AvatarSlider = (props) => {
  const { metricLetter, metricValue } = props;

  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );

  const orientation = isTablet ? 'horizontal' : 'vertical';

  return (
    <Grid
      container
      direction={{ xs: 'column', sm: 'row-reverse', md: 'column' }}
      alignItems={'center'}
      justifyContent={'center'}
      gap={{ xs: 0, sm: 2, md: 0 }}
      marginTop={4}
    >
      <Widget>
        <WidgetText>{metricValue}</WidgetText>
      </Widget>

      <Slider
        value={metricValue}
        orientation={orientation}
        sx={{
          pointerEvents: 'none',
          mx: 'auto',
          marginTop: isTablet ? 0 : 1,
          height: isTablet ? 10 : 140, // Adjust height based on orientation
          width: isTablet ? '70%' : 10, // Adjust width based on orientation
          '& .MuiSlider-thumb': {
            background: '#003865',
            width: 0,
            height: 0,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-rail': {
            backgroundColor: 'white',
            border: '2px solid #012038',
            width: isTablet ? '100%' : 10, // Adjust width based on orientation

            height: isTablet ? 15 : 135, // Adjust height based on orientation
          },
          '& .MuiSlider-track': {
            width: isTablet ? 'auto' : 10, // Adjust width based on orientation
            height: isTablet ? 15 : 'auto', // Adjust height based on orientation
            backgroundColor: '#003865',
          },
        }}
      />

      <MetricText>{metricLetter}</MetricText>
    </Grid>
  );
};

export default function ScoreMetricsBottom(props) {
  const { metrics } = props;

  if (metrics === undefined) return <div>TODO:implement error</div>;

  return (
    <Stack
      direction={{ xs: 'row', sm: 'column', md: 'row' }}
      alignItems={'center'}
      width={{ xs: '100%', md: 150 }}
      maxWidth={{ xs: '250px', sm: '400px' }}
      marginLeft={{
        xs: 'auto',
        md: 5,
      }}
      marginRight={{
        xs: 'auto',
        md: '0',
      }}
    >
      {metrics.map((metric) => (
        <AvatarSlider metricValue={metric.value} metricLetter={metric.letter} />
      ))}
    </Stack>
  );
}
