import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography, Grid, Tooltip, Link } from '@mui/material';

const TransparencyAnalyticsBox = (props) => (
  <Box
    borderRadius={5}
    height={33}
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    {...props}
  />
);

const Label = (props) => (
  <Typography
    color="info.main"
    textAlign="right"
    fontSize={12}
    fontWeight="bold"
    flex={1}
    {...props}
  />
);

const Value = (props) => (
  <Typography
    color="info.dark"
    textAlign="left"
    fontSize={12}
    fontWeight="bold"
    flex={2}
    {...props}
  />
);

const TextValue = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
  
  }, [showTooltip]);

  const truncatedText = children;

  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={
        <Typography fontSize={12} color="white">
          {children}
        </Typography>
      }
    >
      <Link
        display="flex"
        underline="hover"
        onClick={() => setShowTooltip(!showTooltip)}
        fontSize={10}
        sx={{ cursor: 'default' }}
        fontWeight="bold"
        color="#000000"
      >
        <Stack>{truncatedText}</Stack>
      </Link>
    </Tooltip>
  );
};

const Transparencyanalytics = (props) => {
  const {
    eu_sustainable_label,
    sfdr_category,
    exclusion_policy,
    positive_screening,
    sum_of_weights,
    total_weights,
    tags_theme,
    sustainable_tags,
    portfolio_update_date
  } = props;

  return (
    <Stack
      container
      direction="row"
      boxSizing="border-box"
      height="100%"
      pb={0}
      width={'100%'}
    >
      <Grid container sx={{ height: '100%', mt: 2.5, width: '100%' }}>
        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>European sustainable label</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{eu_sustainable_label}</TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>SFDR category</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{sfdr_category}</TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Exclusions policy</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{exclusion_policy}</TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Positive screening</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{positive_screening}</TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Nb of sustainable tags</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value><TextValue>{sustainable_tags}</TextValue></Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Sustainable tags theme</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{tags_theme}</TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Holdings published</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>
              {total_weights === undefined ? '' : `${total_weights}%`}
            </TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>ESG-rated holdings</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>
              {sum_of_weights === undefined ? '' : `${sum_of_weights}%`}
            </TextValue>
          </Value>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>Holding Confidence</Label>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="center">
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',
              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ py: 1.2 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Value>
            <TextValue>{portfolio_update_date}</TextValue>
          </Value>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Transparencyanalytics;
