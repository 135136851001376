import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { Divider } from '@mui/material';
import { Avatar } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';

const Widget = styled(Avatar)(({ theme }) => ({
  background: 'white',
  border: '3px solid',
  borderColor: theme.palette.primary.main,
  height: 35,
  width: 35,
}));

const WidgetText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 12,
  fontWeight: 'bold',
  margin: 'auto',
}));

const WidgetArrow = styled(Divider)(({ theme }) => {
  const width = '110px';

  return {
    width,
    height: '16px',
    borderBottomWidth: '3px',
    borderBottomColor: theme.palette.primary.main,
    marginLeft: 0,
    marginRight: 0,
  };
});

const WidgetLegend = (props) => {
  const { type } = props;

  return type === 'long' ? (
    <Stack direction={'column'} marginRight={1} alignItems="flex-end">
      <Typography fontSize={14} color={'gray'}>
        Global
      </Typography>
      <Typography fontSize={8} color={'gray'}>
        (Controversy corrected)
      </Typography>
    </Stack>
  ) : (
    <Stack
      direction={'column'}
      marginRight={1}
      marginLeft={0}
      marginTop={0.5}
      alignItems="flex-end"
    >
      <Typography fontSize={14} color={'gray'}>
        ESG
      </Typography>
    </Stack>
  );
};

const Metric = (props) => {
  const { type, metricValue } = props;

  return (
    <Grid
      container
      direction={'row'}
      justifyContent="flex-end"
      marginTop={2}
      flex={1}
      paddingRight={0}
    >
      <WidgetLegend type={type} />
      <Box display={'flex'} flexDirection={'row'} mt={0}>
        <Widget>
          <WidgetText>{metricValue}</WidgetText>
        </Widget>
        <WidgetArrow />
      </Box>
    </Grid>
  );
};

export default function ScoreMetricsTopDoc(props) {
  const { globalValue, esgValue } = props;
  return (
    <Stack
      direction="column"
      justifyContent={'center'}
      alignItems={'start'}
      gap={2}
      display={'flex'}
      width={'100%'}
      mt={0}
    >
      <Metric type={'long'} metricValue={globalValue} />
      <Metric type={'short'} metricValue={esgValue} />
    </Stack>
  );
}
