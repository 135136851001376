import * as React from 'react';

function SvgFossilFuel(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 512 640"
      {...props}
    >
      <path d="M497.386 172.554l-379.341-97.84 9.608-37.252a8 8 0 00-5.748-9.745l-19.78-5.1a65.989 65.989 0 00-80.291 47.364l-11.119 43.112A66.013 66.013 0 0049 190.317V221H32.145A7.857 7.857 0 0024 228.679V302a8.123 8.123 0 008.145 8H49v39H32.145A8.325 8.325 0 0024 357.231v73.323A8.516 8.516 0 0032.145 439h52.373A8.814 8.814 0 0093 430.554v-73.323A8.624 8.624 0 0084.518 349H65v-39h19.518c4.419 0 8.482-3.58 8.482-8v-73.321c0-4.419-4.063-7.679-8.482-7.679H65v-25.832l12.86 3.317a7.989 7.989 0 009.745-5.748l9.608-37.253 119.972 31.184L193.978 439h-41.994c-4.418 0-7.984 3.1-7.984 7.519v36.968c0 4.418 3.566 7.513 7.984 7.513h207.023c4.418 0 7.993-3.1 7.993-7.513v-36.968c0-4.419-3.575-7.519-7.993-7.519h-41.993L295.67 206.911 439 243.638V327h-24.663c-4.418 0-8.337 3.4-8.337 7.816v95.551c0 4.419 3.919 7.633 8.337 7.633h68.251c4.418 0 8.412-3.214 8.412-7.633v-95.551c0-4.419-3.994-7.816-8.412-7.816H455v-79.235l21.554 5.559a8 8 0 009.744-5.749l16.836-65.275a8 8 0 00-5.748-9.746zM77 423H40v-58h37zm0-129H40v-57h37zm398 128h-53v-79h53zM74.251 180.994l-12.1-3.1a49.994 49.994 0 01-35.9-60.8l11.1-43.111a49.874 49.874 0 0160.783-35.874l12.171 3.1L84 143.739v.006zM351 475H160v-20h191zm-50.055-36h-90.9l22.834-248.284 46.332 12.07zM472.8 235.833l-371.591-95.842 12.84-49.784 371.6 95.842z" />
    </svg>
  );
}

export default SvgFossilFuel;
