import React, { useState, useEffect } from 'react';
import * as ExclIcons from '../../../../icons/excl';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const ExclusionsCard = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{
        borderRadius: '8px',
        height: height,
        border: '2px solid #003865',
        width: 1,
      }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 20 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent sx={{ padding: 2 }}>{children}</CardContent>
    </Card>
  );
};

export default function ExclusionsDoc(props) {
  const { ...exclusions } = props;

  const [colors, set_colors] = useState(() => {
    let c = {
      Coal: 'green',
      FossilFuels: 'green',
      GMO: 'green',
      Nuclear: 'green',
      Gambling: 'green',
      AnimalTesting: 'green',
      Alcohol: 'green',
      Tobacco: 'green',
      Weapon: 'green',
    };

    for (const [key, value] of Object.entries(exclusions)) {
      let elem = value.sector.replace(' ', '');
      c[elem] = 'red';
    }

    return c;
  });

  const [icons, set_icons] = useState([
    { icon: <ExclIcons.Coal fill={colors.Coal} />, id: 'Coal', display: '' },
    {
      icon: <ExclIcons.FossilFuel fill={colors.FossilFuels} />,
      id: 'Fossil Fuels',
      display: '',
    },
    { icon: <ExclIcons.Gmo fill={colors.GMO} />, id: 'GMO', display: '' },
    {
      icon: <ExclIcons.Nuclear fill={colors.Nuclear} />,
      id: 'Nuclear',
      display: '',
    },
    {
      icon: <ExclIcons.Gambling fill={colors.Gambling} />,
      id: 'Gambling',
      display: '',
    },
    {
      icon: <ExclIcons.AnimalTesting fill={colors.AnimalTesting} />,
      id: 'Animal Testing',
      display: '',
    },
    {
      icon: <ExclIcons.Alcohol fill={colors.Alcohol} />,
      id: 'Alcohol',
      display: '',
    },
    {
      icon: <ExclIcons.Tobacco fill={colors.Tobacco} />,
      id: 'Tobacco',
      display: '',
    },
    {
      icon: <ExclIcons.Weapon fill={colors.Weapon} />,
      id: 'Weapon',
      display: '',
    },
  ]);

  return (
    <ExclusionsCard height={95} header="ACTIVITIES SENSITIVITY">
      <Grid item>
        <Stack justifyContent={'space-between'} direction={'row'}>
          {' '}
          {icons.map((i) => (
            <Box
              sx={{
                display: 'flex',

                width: 30,
              }}
            >
              {i.icon}
            </Box>
          ))}
        </Stack>
      </Grid>
    </ExclusionsCard>
  );
}
