import React from 'react';
import { Grid, Divider } from '@mui/material';
import PortfolioReportExclusionIcons from '../../resources/PortfolioReportExclusionIcons';

export default function ExclusionAnalytics(props) {
  const { coal, fossil_fuels } = props;

  return (
    <Grid
      container
      justifyContent={{ xs: 'center', md: 'space-between' }}
      alignItems={'center'}
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
        pb: {
          xs: 2,
          md: 0,
        },
      }}
    >
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Coal" percent={coal} />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons
          iconName="FossilFuel"
          percent={fossil_fuels}
        />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Nuclear" />
      </Grid>
      {/* Add the rest of the icons following the same pattern */}
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="AnimalTesting" />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Gmo" />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Gambling" />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Tobacco" />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Alcohol" />
      </Grid>
      <Grid item xs={4} md={1}>
        <PortfolioReportExclusionIcons iconName="Weapon" />
      </Grid>
    </Grid>
  );
}
