import * as React from "react";

function SvgAlcohol(props) {
  return (
    <svg className="icon icon__questionnaire" xmlns="http://www.w3.org/2000/svg"  viewBox="-30 -70 140 190">
        <path d="M29.847 85.527L29.847 58.296C29.847 56.525 32.326 54.591 35.465 52.141C43.122 46.164 54.694 37.132 54.694 13.289C54.694 9.555 54.443 5.811 53.949 2.164C53.781 0.925 52.722 0 51.471 0L3.223 0C1.972 0 0.913002 0.925 0.745001 2.164C0.251001 5.811 0 9.555 0 13.289C0 37.132 11.571 46.164 19.229 52.141C22.368 54.591 24.847 56.526 24.847 58.296L24.847 85.527C8.527 85.865 8.513 89.511 8.513 90.75C8.513 92.049 8.513 96 27.347 96C46.181 96 46.181 92.049 46.181 90.75C46.181 89.512 46.167 85.865 29.847 85.527L29.847 85.527ZM5.439 5L49.255 5C49.546 7.735 49.693 10.514 49.693 13.289C49.693 16.876 49.407 20.073 48.905 22.951C45.89 23.569 38.482 24.602 27.978 22.82C16.767 20.918 9.454 21.918 5.76401 22.819C5.27701 19.976 4.99901 16.821 4.99901 13.289C5 10.514 5.148 7.735 5.439 5L5.439 5ZM22.306 48.199C16.946 44.015 10.136 38.689 6.89 27.694C9.915 26.95 16.576 25.957 27.143 27.75C31.836 28.547 35.961 28.824 39.431 28.824C42.818 28.824 45.579 28.559 47.638 28.256C44.329 38.875 37.658 44.087 32.388 48.199C30.483 49.686 28.7 51.078 27.347 52.573C25.993 51.078 24.211 49.687 22.306 48.199L22.306 48.199Z" />
    </svg>
  );
}

export default SvgAlcohol;
