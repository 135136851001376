import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { Divider } from '@mui/material';
import { Avatar } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';

const Widget = styled(Avatar)(({ theme }) => ({
  background: 'white',
  border: '3px solid',
  borderColor: theme.palette.primary.main,
  height: 35,
  width: 35,
}));

const WidgetText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 12,
  fontWeight: 'bold',
  margin: 'auto',
}));

const WidgetArrow = styled(Divider)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const width = isMobile ? '162px' : '70px'; // Set the different width for mobile breakpoint

  return {
    width,
    height: '16px',
    borderBottomWidth: '3px',
    borderBottomColor: theme.palette.primary.main,
    marginLeft: 0,
    marginRight: 0,
  };
});

const WidgetLegend = (props) => {
  const { type } = props;

  return type === 'long' ? (
    <Stack direction={'column'} marginRight={1} alignItems="flex-end">
      <Typography fontSize={{ xs: 14, sm: 20, md: 14 }} color={'gray'}>
        Global
      </Typography>
      <Typography fontSize={{ xs: 8, sm: 12, md: 8 }} color={'gray'}>
        (Controversy corrected)
      </Typography>
    </Stack>
  ) : (
    <Stack
      direction={'column'}
      marginRight={{ xs: 1, sm: 0, md: 1 }}
      marginLeft={{ xs: 0, sm: 1, md: 0 }}
      marginTop={0.5}
      alignItems="flex-end"
    >
      <Typography fontSize={{ xs: 14, sm: 20, md: 14 }} color={'gray'}>
        ESG
      </Typography>
    </Stack>
  );
};

const Metric = (props) => {
  const { type, metricValue } = props;

  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );

  const direction = isTablet
    ? type === 'long'
      ? 'row'
      : 'row-reverse'
    : 'row';
  const boxDirection = isTablet
    ? type === 'long'
      ? 'row-reverse'
      : 'row'
    : 'row';
  const tabletPadding = isTablet ? (type === 'long' ? 2 : 0) : 0;

  return (
    <Grid
      container
      direction={direction}
      justifyContent="flex-end"
      marginTop={2}
      flex={1}
      paddingRight={tabletPadding}
    >
      <WidgetLegend type={type} />
      <Box
        display={'flex'}
        flexDirection={boxDirection}
        mt={{ xs: 0, sm: 1, md: 0 }}
      >
        <Widget>
          <WidgetText>{metricValue}</WidgetText>
        </Widget>
        <WidgetArrow />
      </Box>
    </Grid>
  );
};

export default function ScoreMetricsTop(props) {
  const { globalValue, esgValue } = props;
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row', md: 'column' }}
      justifyContent={'center'}
      alignItems={'start'}
      gap={2}
      display={'flex'}
      width={'100%'}
      mt={{
        sm: 2,
        md: 0,
      }}
    >
      <Metric type={'long'} metricValue={globalValue} />
      <Metric type={'short'} metricValue={esgValue} />
    </Stack>
  );
}
