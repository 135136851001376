import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function FactorySvgIcon(props){
    return (
    <SvgIcon viewBox="-20 0 120 90" >
    <path d="M121.717,108.13v57.632H0v72.988h253.435V84.781L121.717,108.13z M56.553,215.774H14.872v-27.037h41.681V215.774z
		 M117.223,215.774H75.542v-27.037h41.681V215.774z M177.893,215.774h-41.681v-27.037h41.681V215.774z M177.893,168.927h-41.681
		v-27.036h41.681V168.927z M238.562,215.774h-41.681v-27.037h41.681V215.774z M238.562,168.927h-41.681v-27.036h41.681V168.927z"
        />
    </SvgIcon>);
}

export default FactorySvgIcon;