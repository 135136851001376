import * as React from "react";



function ArrowDownSvgIcon(props){
    return (
    <svg {...props}  width="150" viewBox="0 8 23 8" >
        <path d="M4.24076 8.34924C4.60018 7.92991 5.23148 7.88135 5.65081 8.24077L12 13.683L18.3492 8.24077C18.7686 7.88135 19.3999 7.92991 19.7593 8.34924C20.1187 8.76856 20.0701 9.39986 19.6508 9.75929L12.6508 15.7593C12.2763 16.0803 11.7237 16.0803 11.3492 15.7593L4.34923 9.75929C3.9299 9.39987 3.88134 8.76857 4.24076 8.34924Z" fill="#F39200"/>
    </svg>);
}

export default ArrowDownSvgIcon;