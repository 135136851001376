import * as React from "react";

function SvgNuclear(props) {
  return (
    <svg className="icon icon__questionnaire" xmlns="http://www.w3.org/2000/svg" viewBox="-15 -35 70 100">
      <g id="Group">
    <g id="Group">
      <path d="M13.926 3.821C14.129 3.619 14.236 3.34 14.217 3.054C14.199 2.768 14.062 2.504 13.836 2.327C11.894 0.805 9.56597 0 7.10897 0C4.65197 0 2.32597 0.805 0.382971 2.327C0.156971 2.504 0.0189705 2.768 0.00197029 3.054C-0.0160294 3.34 0.0899696 3.619 0.292971 3.821L5.72897 9.256C5.98397 9.512 6.35897 9.608 6.70597 9.512C7.02697 9.422 7.19397 9.423 7.51297 9.512C7.60197 9.537 7.69297 9.549 7.78397 9.549C8.04697 9.549 8.30097 9.446 8.49097 9.256L13.926 3.821L13.926 3.821ZM7.45397 7.465C7.22097 7.439 7.00097 7.439 6.76697 7.465L2.55697 3.255C5.30797 1.607 8.91697 1.607 11.665 3.255L7.45397 7.465L7.45397 7.465Z" transform="translate(11.89003 16.439001)" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
      <path d="M9.64005 3.12803C9.58205 3.02703 9.53705 2.90703 9.49605 2.74504C9.40705 2.39403 9.13505 2.11904 8.78505 2.02403L1.36005 0.0340347C1.08505 -0.0399647 0.789053 0.00803566 0.550053 0.165035C0.311052 0.323036 0.152052 0.576035 0.111053 0.859036C-0.234947 3.30104 0.231054 5.71704 1.46005 7.84704C2.68905 9.97604 4.54906 11.586 6.83805 12.508C6.95806 12.556 7.08505 12.58 7.21105 12.58C7.36405 12.58 7.51805 12.545 7.65805 12.475C7.91405 12.347 8.10305 12.116 8.17705 11.838L10.1661 4.41203C10.2601 4.06003 10.1561 3.68503 9.89405 3.43303C9.77805 3.32604 9.69705 3.22503 9.64005 3.12803L9.64005 3.12803ZM6.55605 10.166C5.15505 9.38804 4.00405 8.25604 3.19205 6.85104C2.38005 5.44404 1.97605 3.88104 2.00205 2.28004L7.75305 3.82104C7.80005 3.92804 7.85205 4.03204 7.90905 4.13204C7.96505 4.23004 8.02805 4.32304 8.09705 4.41704L6.55605 10.166L6.55605 10.166Z" transform="translate(8.077948 24.978964)" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
      <path d="M10.0911 0.859234C10.0521 0.576233 9.89207 0.323235 9.65307 0.165234C9.41307 0.00723267 9.11707 -0.0397663 8.84207 0.0342331L1.41407 2.02423C1.06207 2.11824 0.791069 2.39624 0.702068 2.75024C0.663067 2.90824 0.619068 3.02524 0.556068 3.12924C0.499069 3.22924 0.419067 3.32624 0.30407 3.44024C0.0440712 3.69224 -0.0589294 4.06624 0.0330696 4.41724L2.02107 11.8432C2.09407 12.1182 2.28407 12.3512 2.54007 12.4792C2.68107 12.5482 2.83507 12.5832 2.98707 12.5832C3.11407 12.5832 3.24007 12.5602 3.36007 12.5122C5.65107 11.5892 7.51007 9.97824 8.73907 7.85124C9.96907 5.71623 10.4371 3.30023 10.0911 0.859234L10.0911 0.859234ZM7.00907 6.84723C6.19707 8.25323 5.04807 9.38223 3.64607 10.1612L2.10607 4.41123C2.17307 4.31823 2.23507 4.22523 2.28807 4.13223C2.35007 4.03023 2.40207 3.92723 2.45007 3.81723L8.20107 2.27623C8.22507 3.87623 7.82207 5.43923 7.00907 6.84723L7.00907 6.84723Z" transform="translate(19.719929 24.980766)" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
      <path d="M35 48L34 48L34 6L35 6C36.654 6 38 4.654 38 3C38 1.346 36.654 0 35 0L3 0C1.346 0 0 1.346 0 3C0 4.654 1.346 6 3 6L4 6L4 48L3 48C1.346 48 0 49.346 0 51C0 52.654 1.346 54 3 54L35 54C36.654 54 38 52.654 38 51C38 49.346 36.654 48 35 48L35 48ZM6 10L32 10L32 44L6 44L6 10L6 10ZM2 3C2 2.449 2.449 2 3 2L35 2C35.552 2 36 2.449 36 3C36 3.551 35.552 4 35 4L3 4C2.449 4 2 3.551 2 3L2 3ZM32 6L32 8L6 8L6 6L32 6L32 6ZM6 46L32 46L32 48L6 48L6 46L6 46ZM35 52L3 52C2.449 52 2 51.553 2 51C2 50.447 2.449 50 3 50L35 50C35.552 50 36 50.447 36 51C36 51.553 35.552 52 35 52L35 52Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
    </g>
  </g>
    </svg> 
  );
}

export default SvgNuclear;
