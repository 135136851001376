import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function HomeSvgIcon(props){
    return (
        <SvgIcon {...props} >
            <path d="M33.3334 66.6667V46.6667H46.6667V66.6667H63.3334V40H73.3334L40.0001 10L6.66675 40H16.6667V66.6667H33.3334Z" />
        </SvgIcon>
    );
}

export default HomeSvgIcon;



