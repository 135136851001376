import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import InvestorProfileResults from './InvestorProfileResults';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { Button, DialogContent, Typography } from '@mui/material';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import InvestorMifidResults from './InvestorMifidResults';
import { useLazyQuery } from '@apollo/client';
import {
  GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE,
  GET_EPA,
} from '../../graphql/queries';
import Context from '../../../context';
import { NorsiaHeaderBox } from '../resources/NorsiaCustomBoxes';
import { useTheme } from '@emotion/react';



export default function InvestorProfile() {
  const theme = useTheme();

  theme.typography.h6 = {
    fontSize: '1.05rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.12rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  };

  const [enterprise_client_info_by_enterprise,
    {...get_client}]=useLazyQuery(GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE
    ,{fetchPolicy: 'network-only' });

    const [get_epa,{...analytics}]=useLazyQuery(GET_EPA,{fetchPolicy:'network-only'});
  const { state } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [epa, setEpa] = useState({});
  const [is_complete, set_is_complete] = useState(true);

  const default_epa_values = {
    thematics: null,
    profile: null,
    bubblechart: null,
    wfesg: null,
    exclusions: null,
  };

  const default_mifid_values = {
    mifid_rdo_1: 'N/A',
    mifid_rdo_2: 'N/A',
    mifid_rdo_3: 'N/A',
    mifid_slider_1: 'N/A',
    mifid_slider_2: 'N/A',
  };
  const [mifid_ans, set_mifid_ans] = useState(default_mifid_values);

  useEffect(() => {
    setClients([]);

    
      enterprise_client_info_by_enterprise({variables:{enterprise_uuid:state.loginInfo.enterprise.uuid}})
      .then((response)=>{
          if(response.data.enterprise_client_info_by_enterprise)
          {
             
             const values=response.data.enterprise_client_info_by_enterprise;
              
              values.forEach(({client,survey_link})=>{
                  clients.push({name:`${client.surname} ${client.name}`,uuid:client.uuid,survey_link:survey_link})
              });
              setClients([...clients])
          }
      }) 
      .catch((error)=>{

      })
  }, []);

  const handleClose = () => {
    setEpa({});
    set_is_complete(true);
    setOpen(false);
  };

  const handleClientChange = (option) => {
    setClient({ ...option });
  };

  const handleShowProfile = () => {
    setEpa({});
    setOpen(false);

    
    get_epa({variables:{uuid:client.uuid}})
      .then((response)=>{
        
        if(typeof response.data.get_epa!=='object')
            return;

          if(response.data.get_epa.thematics===null)
              set_is_complete(false)

          if( response.data.get_epa.mifid !==null){
              
              if(Object.keys(response.data.get_epa.mifid).length===6)
              {
                  let {mifid}=response.data.get_epa;
                  set_mifid_ans({...mifid_ans,...mifid})
              }
          }
          else{
            set_mifid_ans({...mifid_ans,...default_mifid_values})
          }
          
          setEpa({...response.data.get_epa,customer_name:client.name})
          setOpen(true);
      }).catch((error)=>{

      });
  };

  if (get_client.loading)
      {
        return (
          <Box sx={{
          flexGrow: 1, marginTop:'10%',
          '& label': {
              color: '#003865',
            },
           }}>Loading clients...</Box>)

      }

  return (
    <Box
      sx={{
        '& label': {
          color: '#003865',
        },
      }}
      className="investor"
    >
      <Grid container spacing={2} margin={0}>
        <Grid
          container
          direction="column"
          xs={12}
          // alignItems="center"
          // justify="center"
          // paddingTop={5}
          mt={6}
        >
          <Box px="8px" marginLeft={2}></Box>
          <Box alignSelf="start" ml="8px" mb={2}>
            <NorsiaHeaderBox sx={{ width: '100%', maxWidth: '600px' }}>
              <Typography variant="h6" color="primary" alignSelf="start">
                Investor Profile
              </Typography>
            </NorsiaHeaderBox>
          </Box>
          <Stack
            direction={'column'}
            spacing={4}
            ml="8px"
            sx={{ width: { xs: '90%', sm: '70%', md: 500 } }}
          >
            <Autocomplete
              freeSolo
              disableClearable
              options={clients}
              // sx={{ width: { xs: 200, sm: 220, md: 400 } }}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                handleClientChange(value);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Stack direction={'row'} spacing={2}>
                    <Typography color={'primary'}>{option.name}</Typography>
                    <Link
                      component={'button'}
                      onClick={() => {
                        window.open(option.survey_link, '_blank').focus();
                      }}
                    >
                      <Typography color={'primary'} fontSize={10}>
                        Go to survey
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{ input: { color: '#003865' } }}
                  {...params}
                  size="small"
                  color="primary"
                  label="Type client name"
                  variant="standard"
                />
              )}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleShowProfile}
              sx={{
                minWidth: '150px',
              }}
            >
              <ShowChartRoundedIcon />
              <Typography variant="h10" color={'white'}>
                Show profile
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        PaperProps={{
          maxWidth: 'auto',
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100%',
            height: '100%',
            '& .MuiPaper-root': {
              maxWidth: { xs: '100%', md: '850px' },
              maxHeight: { xs: '100%', md: 'auto' },
              width: '100%',
              margin: 0,
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{
            width: '100%',
            height: '100%',
            px: {
              xs: 1,
              md: 2,
            },
          }}
        >
          {is_complete ? (
            <InvestorProfileResults
              props={epa}
              mifid={mifid_ans}
              close={handleClose}
            />
          ) : (
            <InvestorMifidResults
              mifid={mifid_ans}
              customer_name={client.name}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
