import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function PortfolioReportSvgIcon(props){
    return (
        <SvgIcon {...props} >
            <path d="M36.6667 6.66667V73.3334C19.6667 71.6667 6.66675 57.3334 6.66675 40C6.66675 22.6667 19.6667 8.33334 36.6667 6.66667ZM43.3334 6.66667V36.6667H73.3334C71.6667 20.6667 59.3334 8.33334 43.3334 6.66667ZM43.3334 43.3334V73.3334C59.0001 71.6667 71.6667 59.3334 73.3334 43.3334H43.3334Z" />
        </SvgIcon>
    );
}

export default PortfolioReportSvgIcon;


