import React from 'react';
import { Grid, Divider } from '@mui/material';
import PortfolioReportExclusionIcons from '../../../resources/PortfolioReportExclusionIcons';

export default function ExclusionAnalyticsDoc(props) {
  const { coal, fossil_fuels } = props;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems={'center'}
      sx={{
        pr: 4,
        pb: 0,
      }}
    >
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Coal" percent={coal} />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons
          iconName="FossilFuel"
          percent={fossil_fuels}
        />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Nuclear" />
      </Grid>
      {/* Add the rest of the icons following the same pattern */}
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="AnimalTesting" />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Gmo" />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Gambling" />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Tobacco" />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Alcohol" />
      </Grid>
      <Grid item xs={1}>
        <PortfolioReportExclusionIcons iconName="Weapon" />
      </Grid>
    </Grid>
  );
}
