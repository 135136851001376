import * as React from 'react';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  label: {
    color: 'white',
    backgroundColor: '#003865',
    '&:hover': {
      color: '#F39200',
    },
    margin: 1,
    height: 40,
    width: 300,
    borderRadius: '4px',
    justifyContent: 'space-between',
  },
});

export default function RemoveClientCheckBox(props) {
  const { children, value, ...other } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      labelPlacement="start"
      control={
        <Checkbox
          className={classes.checkbox}
          label={children}
          icon={<CircleIcon color="primary" />}
          checkedIcon={<CancelRoundedIcon color="secondary" />}
          disableRipple
          {...other}
        />
      }
      label={
        <Typography marginLeft={'30%'} fontSize={14}>
          {children}
        </Typography>
      }
    />
  );
}
