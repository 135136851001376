import * as React from 'react';
import { Box } from '@mui/material';
import styled from "@emotion/styled";



const Top=styled(Box)(({ theme})=> ({
    background:theme.palette.secondary.main,
    transform: 'rotate(50deg)',
    transformOrigin:'bottom right',
    height:'3px',
    width: '100%',
    left: '-5px',
    position: 'absolute'
}));

const Bottom=styled(Box)(({ theme})=> ({
    background:theme.palette.secondary.main,
    transform: 'rotate(-50deg)',
    transformOrigin:'top right',
    height:'3px',
    width: '100%',
    left: '-5px',
    position: 'absolute'
}));

const ArrowContainer= styled(Box)(({ theme})=> ({   
    height: '10px',
    width: '50px',
    transform: 'rotate(90deg)', 
}));


export default function ArrowDown(props){
    return (<ArrowContainer>
                <Top />
                <Bottom bgcolor={'white'}/>
            </ArrowContainer>)
}