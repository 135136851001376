import * as React from 'react';
import ReactECharts from 'echarts-for-react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box, Divider, Grid, Stack } from '@mui/material';
import ArrowDown from './ArrowDown';
import ProfileText from './ProfileText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ProfileCardContent = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{
        borderRadius: '4px',
        height: {
          xs: 'auto',
          md: 350,
        },
        border: 1,
        width: 1,
      }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 10 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent sx={{ bgcolor: 'primary.main', padding: 0, height: 1 }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default function ProfileCard(props) {
  const { cls_sum, cls_sdev } = props;

  return (
    <ProfileCardContent header="PROFILE" fullWidth>
      <Grid
        container
        maxWidth="400px"
        sx={{
          mx: 'auto',
        }}
      >
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={1}
            padding={1}
          >
            <Typography variant="h7" color={'white'}>
              {cls_sum}
            </Typography>
            <Typography variant="h7" color={'white'}>
              {'-'}
            </Typography>
            <Typography variant="h7" color={'white'}>
              {cls_sdev}
            </Typography>
          </Stack>
        </Grid>
        <Box
          width="100%"
          color="secondary.main"
          display="flex"
          justifyContent="center"
          alignItems="center"
          my={'-10px'}
        >
          <KeyboardArrowDownIcon fontSize="large" />
        </Box>
        <Grid container height={1}>
          <ProfileText ProfileType={`${cls_sum}-${cls_sdev}`} />
        </Grid>
      </Grid>
    </ProfileCardContent>
  );
}
