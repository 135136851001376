import React from 'react';
import { Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

const ESGMetricsBox = styled(Box)({
  height: 60,
});

const Metric = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 'bold',
}));

const MetricLegend = styled(Typography)({
  textAlign: 'center',
  fontSize: 9,
  fontWeight: 'bold',
});

export default function TailoredESGMetricsTop(props) {
  return (
    <Stack
      direction={'row'}
      padding={1}
      spacing={1}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-around'}
    >
      <Stack sx={{ width: 145 }} spacing={0.5} direction={'column'}>
        <ESGMetricsBox>
          <Metric>N/A</Metric>
          <MetricLegend>
            Kg of CO2 per $ invested (confidence ratio)
          </MetricLegend>
        </ESGMetricsBox>
        <ESGMetricsBox>
          <Metric>N/A</Metric>
          <MetricLegend>
            Of companies signatory of the UN Global Compact
          </MetricLegend>
        </ESGMetricsBox>
        <ESGMetricsBox>
          <Metric>N/A</Metric>
          <MetricLegend>
            Of companies ensuring freedom of association
          </MetricLegend>
        </ESGMetricsBox>
      </Stack>
      <Stack direction={'column'}>
        <Stack sx={{ width: 145 }} spacing={0.5} direction={'column'}>
          <ESGMetricsBox>
            <Metric>N/A</Metric>
            <MetricLegend>
              Liter of water used to generate 1$ of revenue (confidence ratio)
            </MetricLegend>
          </ESGMetricsBox>
          <ESGMetricsBox>
            <Metric>N/A</Metric>
            <MetricLegend>
              Average proportion of women on the corporate board
            </MetricLegend>
          </ESGMetricsBox>
          <ESGMetricsBox>
            <Metric>N/A</Metric>
            <MetricLegend>
              Of companies linking executives compensation to ESG performance
            </MetricLegend>
          </ESGMetricsBox>
        </Stack>
      </Stack>
    </Stack>
  );
}
