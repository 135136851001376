import * as React from 'react';

function SvgWeapon(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 125"
      {...props}
    >
      <path
        style={{
          textIndent: 0,
          textTransform: 'none',
          blockProgression: 'tb',
        }}
        d="M18.094 22.345l-1 3c-.337.982.24 2.182 1.218 2.531l26.47 9.719L40.968 43c-4.069-.272-8.444.529-11.688 1.375-6.835 2.021-13.365 3.968-19.406 7.312-1.25.7-2.304 1.389-3.156 2.156C5.867 54.612 5 55.501 5 57.001c.002.478.184.953.5 1.312-.534 1.498-.608 3.128-.313 4.687.492 2.6 1.853 5.161 3.625 7.469 3.545 4.615 8.697 8.53 13.875 8.531h56.25c2.281 0 4.412-1.114 6.563-2.656s4.268-3.603 5.969-5.906c1.7-2.304 3.003-4.861 3.406-7.5.228-1.494.101-3.064-.469-4.5.379-.374.598-.905.594-1.437 0-1.667-.707-3.142-1.625-4.625-2.115-3.147-4.713-5.878-7.75-7.781C83.573 43.445 82.109 43 80 43V31.939c0-1.696-.26-3.322-1.438-4.5S75.758 26 74.062 26h-20.25a2.03 2.03 0 00-1.437.844l-3.094 4.406-28.625-10.156c-1.169-.374-1.997.42-2.562 1.25zm36.937 7.656h19.031c1.242 0 1.6.225 1.657.281.056.056.281.415.281 1.657V43H45.844zM37 47.001h44c.333 0 1.358.293 2.5 1 2.761 1.874 5.134 4.148 6.719 7H12.156c5.94-2.967 11.932-5.139 18.125-6.781C33.301 47.427 35.944 47 37 47zM9.687 59h80.344c.82.92 1.125 1.915.906 3.343-.255 1.674-1.25 3.772-2.687 5.719-1.437 1.946-3.29 3.76-5.063 5.03C81.416 74.365 79.67 75 78.937 75h-56.25c-2.855 0-7.735-3.084-10.718-6.968-1.492-1.943-2.52-4.037-2.844-5.75-.266-1.405-.038-2.408.562-3.281zM23 61c-3.29 0-6 2.71-6 6s2.71 6 6 6 6-2.71 6-6-2.71-6-6-6zm18 0c-3.29 0-6 2.71-6 6s2.71 6 6 6 6-2.71 6-6-2.71-6-6-6zm18 0c-3.29 0-6 2.71-6 6s2.71 6 6 6 6-2.71 6-6-2.71-6-6-6zm18 0c-3.29 0-6 2.71-6 6s2.71 6 6 6 6-2.71 6-6-2.71-6-6-6zm-54 4c1.128 0 2 .871 2 2 0 1.128-.872 2-2 2-1.128 0-2-.872-2-2 0-1.129.872-2 2-2zm18 0c1.128 0 2 .871 2 2 0 1.128-.872 2-2 2-1.128 0-2-.872-2-2 0-1.129.872-2 2-2zm18 0c1.128 0 2 .871 2 2 0 1.128-.872 2-2 2-1.128 0-2-.872-2-2 0-1.129.872-2 2-2zm18 0c1.128 0 2 .871 2 2 0 1.128-.872 2-2 2-1.128 0-2-.872-2-2 0-1.129.872-2 2-2z"
        overflow="visible"
        color="#000"
      />
    </svg>
  );
}

export default SvgWeapon;
