import React, { useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Context from '../context';
import MenuIcon from '@mui/icons-material/Menu';

import logo from './img/Norsia Logo App.svg';

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function NorsiaAppBar(props) {
  const navigate = useNavigate();
  const { state } = useContext(Context);

  return (
    <ElevationScroll {...props}>
      <Box>
        <AppBar color="neutral">
          <Toolbar>
            <img src={logo} alt="logo" width={90} height={30} />
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {`${state.loginInfo.enterprise.name} - ${state.loginInfo.name} ${state.loginInfo.surname}`}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <MenuIcon
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
                transform: props.showBox ? 'rotate(90deg)' : 'rotate(0)',
                transition: 'all 300ms ease-in-out',
                zIndex: 99,
                position: 'absolute',
                top: 15,
                right: 5,
                // color: 'primary.main',
                color: props.showBox ? '#F39200' : 'primary.main',
              }}
              onClick={() => props.setHide(!props.hide)}
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => navigate('/login')}
              >
                LOGOUT
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ElevationScroll>
  );
}
