import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import PortfolioReportCardDoc from '../portfolioreport/partials/PDF/PortfolioReportCardDoc';
import ScoreMetricsTopDoc from '../portfolioreport/partials/PDF/ScoreMetricsTopDoc';
import ScoreMetricsBottomDoc from '../portfolioreport/partials/PDF/ScoreMetricsBottomDoc';
import TailoredESGMetricsTop from '../portfolioreport/partials/TailoredESGMetricsTop';
import SustainabilityPerformance from '../portfolioreport/partials/SustainabilityPerformance';
import PortfolioReportCardDocTransparency from '../portfolioreport/partials/PDF/PortfolioReportCardDocTransparency';
import TransparencyanalyticsDoc from '../portfolioreport/partials/PDF/TransparencyAnalyticsDoc';
import ExclusionAnalytics from '../portfolioreport/partials/ExclusionAnalytics';
import styled from '@emotion/styled';
import ExclusionAnalyticsDoc from '../portfolioreport/partials/PDF/ExclusionAnalyticsDoc';
import BubbleChart from './partials/BubbleChart';
import ProfileCard from './partials/ProfileCard';
import Exclusions from './partials/Exclusions';
import ESGPreferences from './partials/ESGPreferencesImpl';
import GlobalPositioning from './partials/GlobalPositioning';
import MifidReport from './partials/MifidReport';
import ReportHeader from './partials/ReportHeader';
import GlobalPositioningDoc from './partials/GlobalPositioningDoc';
import BubbleChartDoc from './partials/BubbleChartDoc';
import ESGPreferencesDoc from './partials/ESGPreferencesImplDoc';
import MifidReportDoc from './partials/MifidReportDoc';
import ExclusionsDoc from './partials/PDF/ExclusionsDoc';

const PortfolioReportButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 150,
  borderRadius: '4px',
}));

const PDFClient = forwardRef((props, ref) => {

  const { mifid, analytics } = props;

  const [PDFview, setPDFView] = useState(props.showPDFView);

  useEffect(() => {
    setPDFView(props.showPDFView);
  }, [props.showPDFView]);

  const dummy_hundred = 100;
  const dummy_sust_performance = {
    moderate: 34,
    poor: 12,
    strong: 28,
    very_strong: 26,
  };
  const dummy_transparency = {
    eu_sustainable_label:
      'ISR, FNG-Siegel, Towards Sustainability, Nordic Swan, ISR, FNG-Siegel, Towards Sustainability, Nordic Swan',
    exclusion_policy:
      'Coal, Nuclear, Weapons, Controversial Weapons, Animal Testing, Tobacco, Alcohol, Coal',
    positive_screening: 'UN Global Compact, OECD Guidelines',
    sfdr_category: '9',
    sum_of_weights: '100',
    total_weights: '100',
    sustainable_tags_theme:
      'emissions, resources use, working conditions, community',
  };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}.${day}.${year}`;
  }

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 30
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        display: PDFview ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      <Box
        height={'100%'}
        width={'100%'}
        position={'absolute'}
        bgcolor={'white'}
        sx={{
          zIndex: 98,
        }}
      />
      <Box
        display={'flex'}
        width={'100%'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        sx={{
          zIndex: 99,
          gap: 1,
        }}
      >
        {/* <PortfolioReportButton
          variant="contained"
          onClick={() => props.setShowPDFView(false)}
        >
          Cancel
        </PortfolioReportButton>
        <PortfolioReportButton variant="contained" onClick={props.handlePrint}>
          Export as PDF
        </PortfolioReportButton> */}

        <CircularProgress variant="determinate" value={progress} />
        <Typography>Generating PDF</Typography>
      </Box>
      <Box ref={ref} minWidth={'762px'}>
        <Box height={'100vh'} display={'flex'}>
          <Box
            sx={{
              width: '30%',
              height: '100%',
              position: 'relative',
            }}
          >
            <img
              style={{
                position: 'absolute',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              src="bg-pdf.jpg"
              alt="norsia-banner"
            />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Box
              color={'primary.main'}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'center'}
              alignItems={'flex-end'}
              flex={1}
              pr={6}
            >
              <Typography color={'#71717a'} fontSize={24} fontWeight={'bold'}>
                {analytics.props.customer_name}
              </Typography>
              <Typography color={'primary.main'} fontSize={16}>
                Investor sustainability profile
              </Typography>
              <Box bgcolor={'secondary.main'} height={'2px'} width={'350px'} />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'end'}
              color={'primary.main'}
              px={6}
              pb={4}
            >
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  position: 'relative',
                }}
              >
                <img
                  src="norsia-logo.png"
                  alt="logo"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    position: 'absolute',
                  }}
                />
              </Box>
              <Typography fontSize={12} mb={1}>
                {formatDate(new Date())}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Report itself */}
        <Box height={'35px'} />
        <Box
          width={'90%'}
          height={'94vh'}
          mx={'auto'}
          px={4}
          py={2}
          boxSizing={'border-box'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'12px'}
          border={'2px solid #003865'}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <Box display={'flex'} gap={2} alignItems={'flex-end'}>
                <Box width={'75px'} height={'75px'} position={'relative'}>
                  <img
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    src="norsia-logo.png"
                    alt="logo"
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={0.5}
                  ml={2}
                  flex={1}
                >
                  <Typography
                    color={'#71717a'}
                    fontSize={20}
                    fontWeight={'bold'}
                  >
                    {analytics.props.customer_name}
                  </Typography>
                  <Typography color={'primary.main'} fontSize={16}>
                    Investor sustainability profile
                  </Typography>
                  <Box
                    bgcolor={'secondary.main'}
                    height={'2px'}
                    width={'100%'}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MifidReportDoc {...mifid} />
              </Grid>
            </Grid>
            <Grid container spacing={3} marginTop={0.2}>
              <Grid item xs={5}>
                <GlobalPositioningDoc {...analytics.props.thematics} />
              </Grid>

              {/* ESG Preferences and Exclusions */}
              <Grid item xs={7}>
                <Stack
                  container
                  display={'flex'}
                  gap="4px"
                  spacing={0.5}
                  direction={'column'}
                >
                  <ESGPreferencesDoc {...analytics.props.wfesg[0]} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <ExclusionsDoc {...analytics.props.exclusions} />
              </Grid>

              {/* Bubble Chart */}
              <Grid item xs={12}>
                <BubbleChartDoc props={analytics.props.bubblechart} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* -------end of report */}
        <Box
          px={6}
          pb={4}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          height={'100vh'}
        >
          <Box height={'25vh'} />
          <Box
            color={'primary.main'}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            mt={6}
          >
            <Typography
              fontSize={24}
              sx={{
                width: '100%',
                borderBottom: '2px solid #F39200',
              }}
            >
              DISCLAIMER
            </Typography>
            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The questionnaire developed by Norsia aims to better understand
              investors' sensitivity to emblematic aspects of sustainable
              development. Like any survey, it has inherent limitations and
              cannot capture the complexity and nuance of investor behavior and
              personality. In addition, investor characteristics change over
              time as a result of various factors, including life events or
              environmental influences. Therefore, the results presented in this
              profile report are only indicative of likely tendencies and at a
              given point in time.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              Users of Norsia profiles must be aware of all the above
              limitations and take them into account in their use.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The release, publication, or distribution of the information
              contained in this report in certain jurisdictions may be
              restricted by law. Persons into whose jurisdictions this report is
              released, published, or distributed should inform themselves about
              and observe any such restrictions.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              Nothing published by Norsia SA constitutes an investment
              recommendation, nor should any data or content published by Norsia
              SA be relied upon for any investment activities; they do not
              constitute, should not be understood, or construed as legal or
              other professional advice.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The information contained in this report is not a substitute for
              financial advice from a professional who is aware of and follows
              up facts and circumstances of individual situations.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              Norsia SA is thus not liable for any damages arising from the use
              of this report or any material contained in it, or from any action
              or decision taken as a result of using the report.
            </Typography>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            width={'100%'}
            mt={2}
          >
            <Box width={'100px'} height={'100px'} position={'relative'}>
              <img
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src="norsia-logo.png"
                alt="logo"
              />
            </Box>

            <Box width={'150px'} height={'100px'} position={'relative'}>
              <img
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src="investment-logo.png"
                alt="logo"
              />
            </Box>
          </Box>
        </Box>
        {/* End of report itself */}
      </Box>
    </Box>
  );
});

export default PDFClient;
