import * as React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const format_value = (value) => {
  switch (value) {
    case 'no':
      return 'NO PREFERENCES';
    case '1-25%':
      return 'BETWEEN 1-25%';
    case '25-50%':
      return 'BETWEEN 25-50%';
    case '50-75%':
      return 'BETWEEN 50-75%';
    case '>75%':
      return 'MORE THAN 75%';
    case 'N/A':
      return 'N/A';
    default:
      throw Error('Underined value');
  }
};

const MifidItem = (props) => {
  const { mifid_header, mifid_value } = props;
  return (
    <Stack
      display={'flex'}
      direction={{ xs: 'column', sm: 'row', md: 'column' }}
      justifyContent={{ xs: 'space-between', md: 'center' }}
      alignItems="center"
      flex={1}
      sx={{
        gap: { xs: 1, sm: 0, md: 1 },
      }}
    >
      <Typography fontSize={10} color={'primary.main'}>
        {mifid_header}
      </Typography>
      <Typography
        fontWeight={'bold'}
        fontSize={10}
        color={'primary.main'}
        borderBottom={2}
        borderColor={'secondary.main'}
      >
        {mifid_value}
      </Typography>
    </Stack>
  );
};

export default function MifidReport(props) {
  const { mifid_rdo_1, mifid_rdo_2, mifid_rdo_3 } = props;

  return (
    <Card sx={{ marginTop: 0, border: 2, borderColor: 'primary.main' }}>
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 5 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            MIFID PREFERENCES
          </Typography>
        }
      />
      <CardContent>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          sx={{
            gap: { xs: 2, md: 0 },
          }}
        >
          <MifidItem
            mifid_header={'ENVIRONMENTALLY SUSTAINABLE INVESTMENTS'}
            mifid_value={format_value(mifid_rdo_2)}
          />
          <MifidItem
            mifid_header={'SUSTAINABLE INVESTMENTS'}
            mifid_value={format_value(mifid_rdo_1)}
          />
          <MifidItem
            mifid_header={'ADVERSE IMPACTS'}
            mifid_value={format_value(mifid_rdo_3)}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
