

const create_report_initialvalues=()=>{
    return {
        customer_name:'N/A',
        fund_objective:'',
        esg_value:'N/A',
        global_value:'N/A',
        portfolio_name:'N/A',
        score_metrics_bottom_values:[ 
            {letter: 'E', value: 'N/A'},
            {letter: 'S', value: 'N/A'},
            {letter: 'G', value: 'N/A'}],
        sust_performance:{very_strong:'N/A',strong:'N/A',moderate:'N/A',poor:'N/A'},
        transparency:{portfolio_update_date:'', eu_sustainable_label:'N/A',sfdr_category:'N/A',
        exclusion_policy:'N/A',positive_screening:'N/A',
        sum_of_weights:'Nothing',
        total_weights:'Nothing',
        tags_theme:null,
        sustainable_tags:null,
        },
        controversy:{coal:null,fossil_fuels:null},
        portfolio_details:{}
        
    };
}

export default create_report_initialvalues;