import * as React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Grid, Typography } from '@mui/material';

const style = {
  height: '105px',
  width: '100%',
  padding: '10px',

  pointerEvents: 'none',
};

const options = {
  animation: false,
  grid: {
    left: '85',
    right: '15%',
    bottom: '03%',
    top: '03%',
    containLabel: false,
  },

  tooltip: {
    trigger: 'item',
    formatter: '{b} {c}%',
    axisPointer: {
      type: 'shadow',
    },
  },

  xAxis: {
    type: 'value',
    splitLine: {
      show: false,
    },
    axisLabel: {
      show: false,
    },
  },

  yAxis: {
    type: 'category',
    inverse: false,
    data: ['Poor', 'Moderate', 'Strong', 'Very Strong'],
  },
};

const create_series = (parameters) => {
  const { poor, moderate, strong, very_strong } = parameters;

  return {
    series: [
      {
        data: [
          { value: Math.round(poor), itemStyle: { color: 'rgb(191,205,216)' } },
          {
            value: Math.round(moderate),
            itemStyle: { color: 'rgb(127,155,178)' },
          },
          {
            value: Math.round(strong),
            itemStyle: { color: 'rgb(64,106,140)' },
          },
          {
            value: Math.round(very_strong),
            itemStyle: { color: 'rgb(0,56,101)' },
          },
        ],
        type: 'bar',
        stack: 'total',
        emphasis: {
          itemStyle: {
            shadowBlur: 5,
            shadowOffsetY: 0,
            shadowColor: 'rgba(0, 0, 0, 1)',
          },
        },
        label: {
          show: true,
          position: 'right',
          formatter: '{c}%',
          fontSize: 12,
        },
        barWidth: '50%',
        itemStyle: {
          normal: {
            borderRadius: [2, 10, 10, 2],
          },
        },
      },
    ],
  };
};

export default function SustainabilityPerformanceDoc(props) {
  const { ...parameters } = props;

  const series = create_series(parameters);

  return (
    <Grid
      container
      sx={{
        maxWidth: '600px',
        mx: 'auto',
      }}
    >
      <Grid item xs={12} className="chart" pl={0}>
        <ReactEcharts option={{ ...options, ...series }} style={style} />
      </Grid>
    </Grid>
  );
}