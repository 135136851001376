import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function SettingsSvgIcon(props){
    return (
        <SvgIcon {...props} >
        <path d="M40 33.3333C36.3333 33.3333 33.3333 36.3333 33.3333 40C33.3333 43.6667 36.3333 46.6667 40 46.6667C43.6667 46.6667 46.6667 43.6667 46.6667 40C46.6667 36.3333 43.6667 33.3333 40 33.3333ZM63.3333 10H16.6667C12.9667 10 10 13 10 16.6667V63.3333C10 67 12.9667 70 16.6667 70H63.3333C67.0333 70 70 67 70 63.3333V16.6667C70 13 67.0333 10 63.3333 10ZM57.5 40C57.5 40.7667 57.4333 41.5333 57.3333 42.2667L62.2667 46.1333C62.7 46.5 62.8333 47.1333 62.5333 47.6333L57.8667 55.7C57.5667 56.2 56.9667 56.4 56.4333 56.2L50.6333 53.8667C49.4333 54.8 48.1 55.5667 46.7 56.1667L45.8333 62.3333C45.7333 62.9 45.2333 63.3333 44.6667 63.3333H35.3333C34.7667 63.3333 34.2667 62.9 34.1667 62.3667L33.3 56.2C31.8667 55.6 30.5667 54.8333 29.3667 53.9L23.5667 56.2333C23.0333 56.4333 22.4333 56.2333 22.1333 55.7333L17.4667 47.6667C17.1667 47.1667 17.3 46.5333 17.7333 46.1667L22.6667 42.3C22.5667 41.5333 22.5 40.7667 22.5 40C22.5 39.2333 22.5667 38.4667 22.6667 37.7333L17.7333 33.8667C17.3 33.5 17.1667 32.8667 17.4667 32.3667L22.1333 24.3C22.4333 23.8 23.0333 23.6 23.5667 23.8L29.3667 26.1333C30.5667 25.2 31.9 24.4333 33.3 23.8333L34.1667 17.6667C34.2667 17.1 34.7667 16.6667 35.3333 16.6667H44.6667C45.2333 16.6667 45.7333 17.1 45.8333 17.6333L46.7 23.8C48.1333 24.4 49.4333 25.1667 50.6333 26.1L56.4333 23.7667C56.9667 23.5667 57.5667 23.7667 57.8667 24.2667L62.5333 32.3333C62.8333 32.8333 62.7 33.4667 62.2667 33.8333L57.3333 37.7C57.4333 38.4667 57.5 39.2333 57.5 40Z" />
        </SvgIcon>
    );
}

export default SettingsSvgIcon;


