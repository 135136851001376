import * as React from "react";

function SvgGambling(props) {
  return (
    <svg className="icon icon__questionnaire" xmlns="http://www.w3.org/2000/svg" viewBox="-10 -50 110 150">
      <path d="M87.445 39.452c-.01-.008-.021-.01-.03-.018-4.459-4.649-10.713-7.557-17.645-7.557-4.915 0-9.484 1.47-13.323 3.971V23.432a5.754 5.754 0 00-5.748-5.748H16.404a5.753 5.753 0 00-5.748 5.748v55.925a5.756 5.756 0 005.748 5.75h34.295a5.757 5.757 0 005.748-5.75v-2.485a24.317 24.317 0 0013.323 3.972c13.498 0 24.48-10.982 24.48-24.484 0-6.537-2.592-12.469-6.78-16.867-.009-.013-.014-.027-.025-.041zm-15.882-3.9c4.575.39 8.726 2.266 11.988 5.136l-2.332 2.222a17.689 17.689 0 00-9.656-4.104v-3.254zm-3.583 0v3.257a17.641 17.641 0 00-8.615 3.3l-2.168-2.4a20.714 20.714 0 0110.783-4.157zM56.69 44.49a17.75 17.75 0 00-4.588 10.003h-3.139c.425-4.771 2.466-9.08 5.568-12.392l2.159 2.389zm-3.825 34.866a2.172 2.172 0 01-2.166 2.167H16.404a2.169 2.169 0 01-2.165-2.167V23.432c0-1.196.969-2.166 2.165-2.166h34.295c1.193 0 2.166.97 2.166 2.166v14.716c0 .165.052.313.093.464-4.712 4.463-7.671 10.76-7.671 17.747 0 6.945 2.918 13.207 7.578 17.665v5.332zm.577-9.986a20.767 20.767 0 01-4.485-11.294h3.104a17.704 17.704 0 003.586 9.204l-2.205 2.09zm14.538 7.799a20.792 20.792 0 01-12.067-5.206l2.181-2.065c2.704 2.362 6.119 3.93 9.887 4.309v2.962zM55.564 56.505c0-7.833 6.372-14.206 14.206-14.206 7.838 0 14.209 6.373 14.209 14.206 0 4.058-1.714 7.712-4.451 10.304-.017.015-.041.019-.059.034-.02.02-.024.04-.045.06-2.537 2.359-5.924 3.813-9.654 3.813-7.833-.001-14.206-6.373-14.206-14.211zm15.999 20.664v-2.963c3.339-.335 6.398-1.611 8.939-3.541l2.005 2.227a20.769 20.769 0 01-10.944 4.277zm13.582-6.704l-2.019-2.239a17.68 17.68 0 004.354-10.149h3.102a20.797 20.797 0 01-5.437 12.388zm2.296-15.972a17.703 17.703 0 00-3.731-9.009l2.326-2.217a20.789 20.789 0 014.538 11.226h-3.133z" />
      <path d="M68.187 53.438c0-1.07.678-1.646 1.586-1.646 1.01 0 1.564.679 1.564 1.79v.516c0 .164.04.207.204.207h2.31c.144 0 .204-.043.204-.207v-.516c0-2.326-1.214-3.768-3.107-4.18v-1.977c0-.145-.063-.204-.207-.204h-1.957c-.162 0-.205.06-.205.204v1.998c-1.834.432-3.048 1.915-3.048 4.158 0 5.147 6.095 3.109 6.095 5.972 0 .988-.598 1.709-1.853 1.709-1.256 0-1.831-.864-1.831-2.04v-.821c0-.145-.064-.207-.207-.207h-2.306c-.164 0-.206.063-.206.207v.821c0 2.472 1.314 4.058 3.355 4.447v1.916c0 .164.043.204.205.204h1.957c.144 0 .207-.04.207-.204V63.65c2.077-.454 3.375-2.06 3.375-4.344 0-5.271-6.135-2.838-6.135-5.868zM38.863 43.925c-1.054-.881-2.177-1.253-3.341-1.092-1.236.164-2.224.902-2.85 1.509-.621-.606-1.611-1.345-2.847-1.509-1.169-.161-2.29.211-3.341 1.092-1.505 1.262-2.293 3.033-2.214 4.984.096 2.411 1.541 6.23 7.883 10.742a.904.904 0 001.04 0c6.343-4.512 7.788-8.331 7.884-10.742.078-1.951-.708-3.722-2.214-4.984z" />
    </svg>
  );
}

export default SvgGambling;
