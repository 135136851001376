import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      light: '#0264b3',
      main: '#003865',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F39200',
      contrastText: '#fff',
    },
    info: {
      main: '#858585',
      dark: '#000000',
    },
    neutral: {
      main: '#ffffff',
    },
    // divider:{
    //   main:'#003865',
    // },
  },
  typography: {
    fontFamily: ['Verdana', 'cursive'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    shadows: [
      'none',
      '0px 15px 60px rgba(0, 0, 0, 0.25)',
      '0px 35px 60px rgba(0, 0, 0, 0.25)',
      '20px 55px 60px rgba(0, 0, 0, 0.25)',
      '10px 15px 60px rgba(0, 0, 0, 0.25)',
      ...Array(20).fill('10px 15px 60px rgba(0, 0, 0, 0.25)'),
    ],
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          color: '#F39200',
          backgroundColor: '#003865',
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
