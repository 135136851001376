import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import PortfolioReportCardDoc from './partials/PDF/PortfolioReportCardDoc';
import ScoreMetricsTopDoc from './partials/PDF/ScoreMetricsTopDoc';
import ScoreMetricsBottomDoc from './partials/PDF/ScoreMetricsBottomDoc';
import TailoredESGMetricsTop from './partials/TailoredESGMetricsTop';
import SustainabilityPerformance from './partials/SustainabilityPerformance';
import PortfolioReportCardDocTransparency from './partials/PDF/PortfolioReportCardDocTransparency';
import TransparencyanalyticsDoc from './partials/PDF/TransparencyAnalyticsDoc';
import ExclusionAnalytics from './partials/ExclusionAnalytics';
import styled from '@emotion/styled';
import ExclusionAnalyticsDoc from './partials/PDF/ExclusionAnalyticsDoc';
import SustainabilityPerformanceDoc from './partials/SustainabilityPerformanceDoc';
import SustainabilityChecksDoc from './partials/PDF/SustainabilityChecksDoc';

const PortfolioReportButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 150,
  borderRadius: '4px',
}));

const PDFreport = forwardRef((props, ref) => {
 

  const [PDFview, setPDFView] = useState(props.showPDFView);

  useEffect(() => {
    setPDFView(props.showPDFView);
  }, [props.showPDFView]);

 

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so adding 1
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 30
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        display: PDFview ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      <Box
        height={'100%'}
        width={'100%'}
        position={'absolute'}
        bgcolor={'white'}
        sx={{
          zIndex: 98,
        }}
      />
      <Box
        display={'flex'}
        width={'100%'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        sx={{
          zIndex: 99,
          gap: 1,
        }}
      >
        {/* <PortfolioReportButton
          variant="contained"
          onClick={() => props.setShowPDFView(false)}
        >
          Cancel
        </PortfolioReportButton>
        <PortfolioReportButton variant="contained" onClick={props.handlePrint}>
          Export as PDF
        </PortfolioReportButton> */}
        <CircularProgress variant="determinate" value={progress} />
        <Typography>Generating PDF</Typography>
      </Box>
      <Box ref={ref}>
        <Box height={'100vh'} display={'flex'}>
          <Box
            sx={{
              width: '30%',
              height: '100%',
              position: 'relative',
            }}
          >
            <img
              style={{
                position: 'absolute',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              src="norsia-img-home.jpg"
              alt="norsia-banner"
            />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} px={6}>
            <Box
              color={'primary.main'}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'end'}
              alignItems={'flex-end'}
              flex={1}
              mb={6}
            >
              <Typography
                color={'#71717a'}
                fontSize={24}
                fontWeight={'bold'}
                align="right"
              >
               {props.portfolio_name}
              </Typography>
              <Typography color={'primary.main'} fontSize={18}>
                Fund/Portfolio Sustainability Analysis
              </Typography>
              <Box bgcolor={'secondary.main'} height={'2px'} width={'350px'} />

              <Typography
                fontStyle={'italic'}
                fontSize={13}
                color={'#71717a'}
                align="justify"
                sx={{
                  maxWidth: '450px',
                }}
              >
                {props.fund_objective}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'end'}
              color={'primary.main'}
              pb={4}
            >
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  position: 'relative',
                }}
              >
                <img
                  src="norsia-logo.png"
                  alt="logo"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    position: 'absolute',
                  }}
                />
              </Box>
              <Typography fontSize={12} mb={1}>
                {formatDate(new Date())}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Report itself */}
        <Box height={'30px'} />
        <Box
          height={'94.5vh'}
          px={4}
          mx={6}
          py={2}
          boxSizing={'border-box'}
          borderRadius={'12px'}
          border={'2px solid #003865'}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display={'flex'} gap={2} alignItems={'flex-end'}>
                <Box width={'75px'} height={'75px'} position={'relative'}>
                  <img
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    src="norsia-logo.png"
                    alt="logo"
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={0.5}
                  ml={2}
                  flex={1}
                >
                  <Typography
                    color={'#71717a'}
                    fontSize={20}
                    fontWeight={'bold'}
                  >
                    {props.portfolio_name.length > 42
                      ? `${props.portfolio_name.substring(0, 42)}...`
                      : props.portfolio_name}
                  </Typography>
                  <Typography color={'primary.main'} fontSize={16}>
                    Fund/Portfolio Sustainability Analysis
                  </Typography>
                  <Box
                    bgcolor={'secondary.main'}
                    height={'2px'}
                    width={'100%'}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} py={'auto'}>
                <PortfolioReportCardDoc headerText="SCORE">
                  <ScoreMetricsTopDoc
                     globalValue={props.global_value}
                     esgValue={props.esg_value}
                  />
                  <ScoreMetricsBottomDoc
                    // metrics={props.score_metrics_bottom_values}
                    metrics={[
                      { letter: 'E',value: props.score_metrics_bottom_values[0].value },
                      { letter: 'S', value:props.score_metrics_bottom_values[1].value },
                      { letter: 'G', value: props.score_metrics_bottom_values[2].value },
                    ]}
                  />
                </PortfolioReportCardDoc>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                <PortfolioReportCardDoc headerText="SUSTAINABILITY PERFORMANCE">
                  <Stack direction={'column-reverse'}>
                    <SustainabilityPerformanceDoc
                     
                      {...props.sustperformance}
                    />
                  </Stack>
                </PortfolioReportCardDoc>
                <PortfolioReportCardDoc headerText="SUSTAINABILITY SNAPSHOT">
                    <SustainabilityChecksDoc {...props.controversy} />
                  </PortfolioReportCardDoc>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <PortfolioReportCardDocTransparency headerText="TRANSPARENCY">
                <TransparencyanalyticsDoc
                  {...props.transparency}
                 
                />
              </PortfolioReportCardDocTransparency>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems={'center'}
                overflow={'hidden'}
                spacing={2}
                border={'1px solid #003865'}
                borderRadius="8px"
              >
                <Box
                  sx={{
                    background: '#003865',
                    height: 50,
                    width: '230px',

                    minWidth: '230px',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    fontWeight={1}
                    color={'white'}
                    textAlign={'center'}
                    justifyContent={'center'}
                    variant="h7"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    height={'100%'}
                  >
                    CONTROVERSIAL ACTIVITIES
                  </Typography>
                </Box>
                <ExclusionAnalyticsDoc {...props.controversy} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          px={6}
          pb={4}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          height={'100vh'}
        >
          <Box height={'25vh'} />
          <Box
            color={'primary.main'}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            mt={6}
          >
            <Typography
              fontSize={24}
              sx={{
                width: '100%',
                borderBottom: '2px solid #F39200',
              }}
            >
              DISCLAIMER
            </Typography>
            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              This report is based only on the long positions of the holdings
              for which data is available to Norsia SA. All other positions have
              been ignored.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The reader of this report should especially be aware that it
              reflects a situation at a given time, as extracted from specific
              sources, and does not constitute prediction analysis. As such, the
              information presented in this report may differ from reality at
              any given time, and Norsia does not warrant the accuracy of the
              information despite its best efforts. Norsia does not conduct
              field-checks.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The information in this report is not intended for marketing
              purposes, and the fund or portfolio analyzed may not be registered
              for public distribution in certain countries and may not be
              suitable for all types of investors.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The release, publication, or distribution of the information
              contained in this report in certain jurisdictions may be
              restricted by law. Persons into whose jurisdictions this report is
              released, published, or distributed should inform themselves about
              and observe any such restrictions.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              ESG profiling and the compatibility assessment provided thereof
              does not constitute financial advice in any way whatsoever.
              Nothing published by Norsia SA constitutes an investment
              recommendation, nor should any data or content published by Norsia
              SA be relied upon for any investment activities; they do not
              constitute, should not be understood, or construed as legal or
              other professional advice.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              The information contained in this report is not a substitute for
              financial advice from a professional who is aware of and follows
              up facts and circumstances of individual situations.
            </Typography>

            <Typography variant="body1" textAlign={'justify'} fontSize={'12px'}>
              Norsia SA is thus not liable for any damages arising from the use
              of this report or any material contained in it, or from any action
              or decision taken as a result of using the report.
            </Typography>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            width={'100%'}
            mt={2}
          >
            <Box width={'100px'} height={'100px'} position={'relative'}>
              <img
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src="norsia-logo.png"
                alt="logo"
              />
            </Box>

            <Box width={'150px'} height={'100px'} position={'relative'}>
              <img
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src="investment-logo.png"
                alt="logo"
              />
            </Box>
          </Box>
        </Box>
        {/* End of report itself */}
      </Box>
    </div>
  );
});

export default PDFreport;
