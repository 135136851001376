import React,{ useState,useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import jsonData from '../../../dummyData/Dummy DATA portfolio details.json';
import { Tooltip } from 'chart.js';

const columns = [
  {
    field: 'isin',
    headerClassName: 'custom-header',
    headerName: 'ISINs',
    flex: 1,
  },
  {
    field: 'weight',
    headerClassName: 'custom-header',
    headerName: 'Weight',
    flex: 1,
    align: 'center',
    minWidth: 100,
    renderCell: (params) => (
      <span>{(params.value).toFixed(2) + '%'}</span>
    ),
  },
  {
    field: 'company_name',
    headerClassName: 'custom-header',
    headerName: 'Company',
    flex: 1,
  },
  {
    field: 'pm_7',
    headerClassName: 'value-header',
    headerName: 'Global',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'pm_1',
    headerClassName: 'value-header',
    headerName: 'ESG',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'pm_3',
    headerClassName: 'value-header',
    headerName: 'E',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'pm_4',
    headerClassName: 'value-header',
    headerName: 'S',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'pm_5',
    headerClassName: 'value-header',
    headerName: 'G',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'nor_coal',
    headerClassName: 'value-header',
    headerName: 'Coal',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
  {
    field: 'nor_fossilfuels',
    headerClassName: 'value-header',
    headerName: 'Fossil Fuels',
    flex: 1,
    align: 'center',
    minWidth: 100,
  },
];



const GridReport = (props) => {
  const {data}=props;
  const [rows,setRows]=useState([])
  
  useEffect(()=>{
    setRows(()=>data.map((item, i) => ({
      id: i,
      ...columns.reduce((rowData, column) => {
        if (typeof item[column.field] === 'number') {
          rowData[column.field] = Math.round(item[column.field]*100);
        } 
        else if(column.field==='nor_coal' || column.field==='nor_fossilfuels'){
          parseInt(item[column.field])===1
            ?rowData[column.field] = 'Yes'
            :rowData[column.field] = 'No';
        }
        else {
          rowData[column.field] = item[column.field];
        }
        return rowData;
      }, {}),
    })));
  },[])

  return (
    <DataGrid
      columns={columns.map((col) => ({
        ...col,
        flex: col.flex || 1,
        minWidth: 120,
      }))}
      rows={rows}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          csvOptions: { fileName: 'Portfolio Report Details' },
          printOptions: { disableToolbarButton: true },
        },
      }}
      initialState={{
        pagination: { paginationModel: { pageSize: 50 } },
      }}
      pageSizeOptions={[5, 10, 25, 50]}
    />
  );
};

export default GridReport;
