import React, {
  useState,
  useEffect,
  createRef,
  useRef,
  forwardRef,
} from 'react';
import { Box, Stack, Grid, Typography, Button } from '@mui/material';

import PortfolioReportCard from './partials/PortfolioReportCard';

import ExclusionAnalytics from './partials/ExclusionAnalytics';
import ReactToPdf from 'react-to-pdf';

import Transparencyanalytics from './partials/Transparencyanalytics';

import ScoreMetricsTop from './partials/ScoreMetricsTop';
import ScoreMetricsBottom from './partials/ScoreMetricsBottom';
import TailoredESGMetricsTop from './partials/TailoredESGMetricsTop';
import SustainabilityChecks from './partials/SustainabilityChecks';
import styled from '@emotion/styled';
import ReportHeader from './partials/ReportHeader';
import SustainabilityPerformance from './partials/SustainabilityPerformance';
import { useReactToPrint } from 'react-to-print';
import ScoreMetricsTopDoc from './partials/PDF/ScoreMetricsTopDoc';
import ScoreMetricsBottomDoc from './partials/PDF/ScoreMetricsBottomDoc';
import PortfolioReportCardDoc from './partials/PDF/PortfolioReportCardDoc';
import TransparencyanalyticsDoc from './partials/PDF/TransparencyAnalyticsDoc';
import PortfolioReportCardDocTransparency from './partials/PDF/PortfolioReportCardDocTransparency';
import PDFreport from './PDFreport';
import GridReport from './Grid';

const PortfolioReportButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 150,
  borderRadius: '4px',
}));

export default function PortfolioReportResults(props, close) {
  const [showDetails, setShowDetails] = useState(false);
  const [showPDFView, setShowPDFView] = useState(false);

  const PDFhandler = () => {
    setShowPDFView(true);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setShowPDFView(false);
    },
  });

  const handleTimeout = () => {
    setShowPDFView(true);
    setTimeout(myTimeoutFunction, 3000);

    // clearTimeout(timeout);
  };

  const myTimeoutFunction = () => {
    handlePrint();
  };

  const {portfolio_details}=props;
  
  return (
    <Box width="100%"
    sx={{
      maxHeight: !showPDFView ? 'auto' : '90vh',
      overflow: !showPDFView ? 'auto' : 'hidden',
    }}
    >
      <Grid container spacing={2} display={showPDFView ? 'none' : 'flex'}>
        <Grid item xs={12}>
          <ReportHeader
            companySnapshot={props.portfolio_name}
            customerName={props.customer_name}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <PortfolioReportCard headerText="SCORE">
              <ScoreMetricsTop
                 globalValue={props.global_value}
                 esgValue={props.esg_value}    
              />
              <ScoreMetricsBottom
                 metrics={props.score_metrics_bottom_values} 
              />
            </PortfolioReportCard>

            <PortfolioReportCard headerText="TRANSPARENCY">
              <Transparencyanalytics
                 {...props.transparency}
              />
            </PortfolioReportCard>
            <Box
              display={'flex'}
              flexDirection={'column'}
              height={{
                xs: '100%',
                md: '425px',
              }}
              gap={2}
            >
            <PortfolioReportCard headerText="SUSTAINABILITY PERFORMANCE">
              <Stack direction={'column-reverse'}>
                
                <SustainabilityPerformance
                  {...props.sustperformance}
                />
              </Stack>
            </PortfolioReportCard>
            <PortfolioReportCard headerText="SUSTAINABILITY SNAPSHOT">
                {/* <TailoredESGMetricsTop /> */}
                <SustainabilityChecks {...props.controversy}/>
              </PortfolioReportCard>
              </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={'center'}
            spacing={2}
            border={'1px solid #003865'}
            borderRadius="4px"
          >
            <Box
              sx={{
                background: '#003865',
                height: 50,
                width: {
                  xs: '100%',
                  md: '230px',
                },
                minWidth: { md: '230px' },
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography
                variant="h7"
                fontWeight={1}
                color="white"
                display={{ xs: 'flex', md: 'none' }}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                textAlign={'center'}
                height={'100%'}
              >
                CONTROVERSIAL ACTIVITIES
              </Typography>
              <Typography
                fontWeight={1}
                color={'white'}
                textAlign={'center'}
                justifyContent={'center'}
                variant="h7"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                height={'100%'}
              >
                CONTROVERSIAL ACTIVITIES
              </Typography>
            </Box>
            <ExclusionAnalytics {...props.controversy} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={1} padding={1}>
            <Box
              sx={{
                mr: 'auto',
              }}
            >
              <PortfolioReportButton
                variant="contained"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails && Object.keys(portfolio_details).length>0 ? 'Hide Details' : 'Show Details'}
              </PortfolioReportButton>
            </Box>
            <Box
            sx={{
              mx: {
                xs: 'auto',
                sm: 0,
              },
            }}
            >
              <PortfolioReportButton variant="contained" onClick={handleTimeout}>
              Export as PDF
              </PortfolioReportButton>
            </Box>
            <Box 
            sx={{
              mx: {
                xs: 'auto',
                sm: 0,
              },
            }}
            onClick={props.close}>
              <PortfolioReportButton variant="contained">
                Close
              </PortfolioReportButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <PDFreport
        handlePrint={handlePrint}
        showPDFView={showPDFView}
        ref={componentRef}
        {...props}
      />
      {showDetails&&Object.keys(portfolio_details).length>0 ? <GridReport data={portfolio_details}/> : null}
    </Box>
  );
}
