import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function FundsScreenerSvgIcon(props){
    return (
        <SvgIcon {...props} >
            <path d="M70.1333 40.4333C70.6 40.4333 71.0333 40.6333 71.4 41L75.6667 45.2667C76.4 45.9667 76.4 47.1333 75.6667 47.8333L72.3333 51.1667L65.5 44.3333L68.8333 41C69.2 40.6333 69.6667 40.4333 70.1333 40.4333ZM63.5667 46.2667L70.4 53.1L50.2 73.3333H43.3333V66.4667L63.5667 46.2667ZM36.6667 63.3333L30 70H16.6667C13 70 10 67 10 63.3333V16.6667C10 13 13 9.99999 16.6667 9.99999H30.6C32 6.13333 35.6667 3.33333 40 3.33333C44.3333 3.33333 48 6.13333 49.4 9.99999H63.3333C67 9.99999 70 13 70 16.6667V30L63.3333 36.6667V16.6667H56.6667V23.3333H23.3333V16.6667H16.6667V63.3333H36.6667ZM40 9.99999C38.1667 9.99999 36.6667 11.5 36.6667 13.3333C36.6667 15.1667 38.1667 16.6667 40 16.6667C41.8333 16.6667 43.3333 15.1667 43.3333 13.3333C43.3333 11.5 41.8333 9.99999 40 9.99999Z" />
        </SvgIcon>
    );
}

export default FundsScreenerSvgIcon;

