import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { NorsiaHeaderBox } from '../resources/NorsiaCustomBoxes';
import Typography from '@mui/material/Typography';
import RemoveClientCheckBox from '../resources/NorsiaRemoveClientCheckBox';
import { Button } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { indigo } from '@mui/material/colors';

import LinearProgress from '@mui/material/LinearProgress';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE } from '../../graphql/queries';
import Context from '../../../context';
import { DELETE_ENTERPRISE_CLIENT } from '../../graphql/mutations';

export default function RemoveClient() {
  const theme = useTheme();

  theme.typography.h6 = {
    fontSize: '1.05rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.12rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  };

  const [enterprise_client_info_by_enterprise,{...get_client}]=useLazyQuery(GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE
      ,{fetchPolicy: "network-only" });
    
    const [delete_enterprise_client,{...delete_client}]=useMutation(DELETE_ENTERPRISE_CLIENT);

  const { state } = useContext(Context);

  const [clientsToRemove, setClientsToRemove] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setClientsToRemove({});
    
    setClientsToRemove({ ...clientsToRemove });
    enterprise_client_info_by_enterprise({variables:{enterprise_uuid:state.loginInfo.enterprise.uuid}})
        .then((response)=>{
            if(response.data.enterprise_client_info_by_enterprise)
            {
                const values=response.data.enterprise_client_info_by_enterprise;
                values.forEach(({client})=>{
                    clientsToRemove[client.uuid]={name:`${client.name} ${client.surname}`,remove:false};
                });
                setClientsToRemove({...clientsToRemove})
            }
        }) 
        .catch((error)=>{

        })
  }, []);

  const handleCheck = (evt, key) => {
    if (evt.target.checked) {
      clientsToRemove[key].remove = evt.target.checked;
      setClientsToRemove({ ...clientsToRemove });
    }
  };

  const handleRemoveClient = () => {
    Object.keys(clientsToRemove).map((key) => {
      let { remove } = clientsToRemove[key];

      if (remove) {
        delete_enterprise_client({variables:{uuid:key}})
              .then((response)=>{
                  if(response.data.delete_enterprise_client)
                      delete clientsToRemove[key]
                      setClientsToRemove({...clientsToRemove})
              })
      }
    });

    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        marginLeft: '0%',
        marginRight: { xs: '3%', xl: '3%' },
      }}
    >
      <Grid container spacing={2} mt={6}>
        <Grid item xs={12} className="remove-title">
          <NorsiaHeaderBox sx={{ width: '100%', maxWidth: '600px' }}>
            <Typography variant="h6" color="primary">
              Select the client(s) to be removed
            </Typography>
          </NorsiaHeaderBox>
        </Grid>
        <Grid item xs={8} lg={7}>
          {get_client.loading && <LinearProgress />}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              maxWidth: 630,
              height: 500,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
              },
            }}
          >
            {Object.keys(clientsToRemove).map((key) => (
              <RemoveClientCheckBox
                value={0}
                onClick={(evt) => {
                  handleCheck(evt, key);
                }}
              >
                {clientsToRemove[key].name}
              </RemoveClientCheckBox>
            ))}
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ marginTop: 2, marginRight: 5 }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpen}
              sx={{ fontSize: '1em', height: 40, marginLeft: { xs: 5, md: 1 } }}
              fullWidth
            >
              <Typography fontSize={{ xs: 12, sm: 14, md: 15, lg: 17, xl: 18 }}>
                Remove
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ backgroundColor: indigo[50] }}>
          <DialogContentText>
            <Typography
              fontSize={{ xs: 12, sm: 14, md: 15, lg: 17, xl: 18 }}
              color="primary"
            >
              Are you sure you want to <b>permanently</b> remove the client?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: indigo[50] }}>
          <Stack
            direction={'row'}
            justifyContent="space-between"
            spacing={{ xs: 11, sm: 15, md: 19, lg: 18, xl: 18 }}
            margin={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ width: { xs: 135, sm: 150, lg: 180, xl: 200 } }}
            >
              <Typography fontSize={{ xs: 12, sm: 14, md: 15, lg: 17, xl: 18 }}>
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRemoveClient}
              sx={{ width: { xs: 135, sm: 150, lg: 180, xl: 200 } }}
            >
              <Typography fontSize={{ xs: 12, sm: 14, md: 15, lg: 17, xl: 18 }}>
                Remove Client
              </Typography>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
