import React, { Component, useEffect, useState } from 'react';

import {
  Box,
  Tooltip,
  Stack,
  Typography,
} from '@mui/material';
import {  makeStyles } from '@mui/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const SheetToolTip = ({ params }) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

 return params.value===null || params.value===undefined?  null
  :  (
    <Tooltip
      classes={{ tooltip: classes.customTooltip }}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={params.value.toString()}
      placement="bottom"
      onClick={() => setShowTooltip(!showTooltip)}
    >
      <span>{params.value.toString().substring(0, 20)}</span>
    </Tooltip>
  );

};

const useStyles = makeStyles((theme) => ({
  nonSelectable: {
    userSelect: 'none',
  },
}));

const formatString=(value)=>{
   if(value===null || value===undefined)
        return ''
  return value.toString();
}

const SpreadSheet = (props) => {
  const [showingObjects, setShowingObjects] = useState([]);

  const classes = useStyles();

  const keyCount = Object.keys(props.filterValues).length;

  useEffect(() => {
    if (keyCount > 100) {
      const first100Objects = props.filterValues.slice(0, 100);
      setShowingObjects(first100Objects);
    }
  }, []);

  const handleQueryClick = (value) => {
    // Get the current URL
    let currentUrl = window.location.href;

    let queryParamName = 'ISIN';
    let queryParamValue = value;

    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.split('?')[0];
      currentUrl += `?${queryParamName}=${queryParamValue}`;
    } else {
      currentUrl += `?${queryParamName}=${queryParamValue}`;
    }

    window.history.replaceState(null, null, currentUrl);
    props.setValue(4);
  };

  useEffect(() => {
    if (keyCount > 100) {
   
      const first100Objects = props.filterValues.slice(0, 100);
      setShowingObjects(first100Objects);
    } 
    else setShowingObjects(props.filterValues);
  }, [props.filterValues]);

  const columns = [
    {
      field: 'nor_isin',
      headerClassName: 'custom-header',
      headerName: 'ISIN',
      flex: 1,
      renderCell: (params) => (
        <Box
          color={'secondary.main'}
          sx={{
            borderBottom: '1px solid white',
            '&:hover': {
              borderBottom: '1px solid #F39200',
            },
            cursor: 'pointer',
          }}
          onClick={() => handleQueryClick(params.value)}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'tr_fundname',
      headerClassName: 'custom-header',
      headerName: 'Fund Name',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundcompany',
      headerClassName: 'custom-header',
      headerName: 'Fund Manager',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundobjective',
      headerClassName: 'custom-header',
      headerName: 'Objective',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundtype',
      headerClassName: 'custom-header',
      headerName: 'Fund Type',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundcurrency',
      headerClassName: 'custom-header',
      headerName: 'Fund Currency',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundgeographicfocus',
      headerClassName: 'custom-header',
      headerName: 'Fund Geographic Focus',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundincdistributionindicator',
      headerClassName: 'custom-header',
      headerName: 'Fund Distribution Policy',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_funddomicile',
      headerClassName: 'custom-header',
      headerName: 'Fund Domicile',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
      
    },
    {
      field: 'tr_fundter',
      headerClassName: 'custom-header',
      headerName: 'Fund TER',
      flex: 1,
     renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'tr_fundlegalstructure',
      headerClassName: 'custom-header',
      headerName: 'Fund Legal Structure',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    // {
    //   field: 'Fund Benchmark Name',
    //   headerClassName: 'custom-header',
    //   headerName: 'Fund Benchmark Name',
    //   flex: 1,
    // },
    // {
    //   field: 'Fund Classification Name',
    //   headerClassName: 'custom-header',
    //   headerName: 'Fund Classification Name',
    //   flex: 1,
    // },
    {
      field: 'nor_sfdrarticle',
      headerClassName: 'custom-header',
      headerName: 'NOR.SFDRArticle',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_isr',
      headerClassName: 'custom-header',
      headerName: 'Label ISR',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_towardsustainability',
      headerClassName: 'custom-header',
      headerName: 'Label Towardsustainability',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_luxflag',
      headerClassName: 'custom-header',
      headerName: 'Label LuxFlag',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_fng_siegel',
      headerClassName: 'custom-header',
      headerName: 'Label FNG-Siegel',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_greenfin',
      headerClassName: 'custom-header',
      headerName: 'Label GreenFin',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_austrian',
      headerClassName: 'custom-header',
      headerName: 'Label Umweltzeichnen',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_nordicswan',
      headerClassName: 'custom-header',
      headerName: 'Label Nordic Swan',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_finansol',
      headerClassName: 'custom-header',
      headerName: 'Label Finansol',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_controversalweapons',
      headerClassName: 'custom-header',
      headerName: 'Controversal Weapons',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_weapons',
      headerClassName: 'custom-header',
      headerName: 'Weapons',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_alcohol',
      headerClassName: 'custom-header',
      headerName: 'Alcohol',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_tobacco',
      headerClassName: 'custom-header',
      headerName: 'Tobacco',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_nuclearpower',
      headerClassName: 'custom-header',
      headerName: 'Nuclear',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_coal',
      headerClassName: 'custom-header',
      headerName: 'Coal',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_gambling',
      headerClassName: 'custom-header',
      headerName: 'Gambling',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_animaltesting',
      headerClassName: 'custom-header',
      headerName: 'Animal Testing',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_geneticengineering',
      headerClassName: 'custom-header',
      headerName: 'Genetic Engineering',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_sustainabletag',
      headerClassName: 'custom-header',
      headerName: 'Profile Specific Tags',
      flex: 1,
      renderCell: (params) => <SheetToolTip params={params} />,
    },
    {
      field: 'nor_theme',
      headerClassName: 'custom-header',
      headerName: 'Specific Tags Count',
      flex: 1,
    },
  ];

  const rows = showingObjects.map((item, i) => ({
    id: i,
    ...columns.reduce((rowData, column) => {
      if(column.field==='nor_theme' || column.field==='nor_sfdrarticle')
      {
        if(typeof parseInt(item[column.field])==='number')
                rowData[column.field] = parseInt(item[column.field]);
        else
            rowData[column.field] = item[column.field];
      }
      else{
        rowData[column.field] = item[column.field];
      }
      return rowData;
    }, {}),
  }));
  

  return (
    <Box
      sx={{
        // width: {
        //   xs: '80vw',
        //   md: '65vw',
        // },
        maxWidth: '100%',
      }}
      pb={2}
      className={classes.nonSelectable}
    >
      <Stack
        sx={{
          border: '2px solid #003865',
          p: 1,
          borderRadius: '4px',
          mb: 1,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: 1,
          }}
        >
          {Object.keys(props.filterValues).length > 100
            ? "More than 100 funds match what you're looking for, we suggest you refine your request"
            : `${Object.keys(showingObjects).length} results found`}
        </Typography>
        <Typography
          color={'secondary.main'}
          sx={{
            borderBottom: '1px solid white',
            '&:hover': {
              borderBottom: '1px solid #F39200',
            },
            cursor: 'pointer',
            width: 'fit-content',
          }}
          onClick={props.resetFilters}
        >
          Reset criteria
        </Typography>
      </Stack>
      <Box height={600}>
        <DataGrid
          columns={columns.map((col) => ({
            ...col,
            flex: col.flex || 1,
            minWidth: col.minWidth || 150,
          }))}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: {
                disableToolbarButton: true,
              },
            },
          }}
          rows={rows}
          initialState={{ pinnedColumns: { left: ['fund name'] } }}
        />
      </Box>
    </Box>
  );
};

export default SpreadSheet;