import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import logo from './img/Norsia Logo App.svg';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// import UseFormControl from './partials/TextBoxUnstyledLoginPage';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION, UPDATE_PASSWORD } from './graphql/mutations';
import Alert from '@mui/material/Alert';
import Context from '../context';
import Footer from './partials/Footer';

export default function Login(props) {
  const [login,{...login_callback}]=useMutation(LOGIN_MUTATION)
  const [update_password,{...update_password_callback}]=useMutation(UPDATE_PASSWORD)
  const { dispatch } = useContext(Context);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);

  const [checked, setChecked] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked) setAcceptTerms(false);
  }, [checked]);

  const changeWarningText = (text) => {
    switch (text) {
      case 'password':
        setCreateAccount(false);
        setForgotPassword(true);
        setAcceptTerms(false);
        break;
      case 'account':
        setCreateAccount(true);
        setForgotPassword(false);
        setAcceptTerms(false);
        break;
      case 'terms':
        setAcceptTerms(true);
        setCreateAccount(false);
        setForgotPassword(false);
        break;

      default:
        break;
    }
  };

  const theme = useTheme();
  const navigate = useNavigate();
  theme.typography.h3 = {
    fontSize: '1.5rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.8rem',
    },
  };

  const [loginInput, setLoginInput] = useState({
    email: '',
    password: '',
  });

  const [passwordChangeInput, setPasswordChangeInput] = useState({
    uuid: '',
    new_password: '',
    confirm_new_password: '',
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginInputchange = (key, event) => {
    setLoginInput({ ...loginInput, [key]: event.target.value.trim() });
  };

  const handlePasswordUpdateInputChange = (key, event) => {
    setPasswordChangeInput({
      ...passwordChangeInput,
      [key]: event.target.value.trim(),
    });
  };

  const handleLogin = () => {
    if (!checked) {
      changeWarningText('terms');
      return;
    }
  
    login({variables:loginInput})
      .then((response)=>{
          if (!response.data.login)
              throw new Error('Login failed');;

          const {is_temp_password,...user_info}=response.data.login;

          if(is_temp_password==='undefined')
              throw new Error('Login failed');

          dispatch({type:'IS_LOGGED_IN',payload:true})
          dispatch({type:'LOGIN_USER',payload:user_info})
          
          if(is_temp_password){
              setPasswordChangeInput({...passwordChangeInput,uuid:user_info.uuid});
              handleOpen();
            }
          else{
              navigate('/home');
          }
      }).catch(error=>{

      })
  };

  const handlePasswordChange = () => {
    const { confirm_new_password, ...change_input } = passwordChangeInput;

    if (!(confirm_new_password || change_input.new_password))
      throw new Error('Invalid value');

    if (confirm_new_password !== change_input.new_password)
      throw new Error('Password does not match');

     update_password({variables:change_input})
        .then((response)=>{
            if(response.data.update_password==='undefined')
                return;
            
            if(response.data.update_password)
                  navigate('/home');
        })
  };

  return (
    <Box minHeight={'100%'} width="100%" display="flex" flexDirection="column">
      <Grid
        container
        spacing={2}
        alignItems="center"
        margin={0}
        className="cont"
        sx={{
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ marginTop: '2%' }}>
            <img src={logo} alt="logo" width={90} height={30} />
          </Box>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          margin={0}
        >
          <Hidden mdDown>
            <Box
              sx={{
                width: { sm: '50%', md: '55%' },
                borderBottom: 2,
                borderColor: 'secondary.main',
                marginTop: 'auto',
                marginBottom: '110px',
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Typography
                color="primary"
                fontWeight={'bold'}
                variant="h3"
                textAlign={'center'}
              >
                Leveraging Investor Values
              </Typography>
            </Box>
          </Hidden>
          <Box
            component={'form'}
            boxSizing="border-box"
            mx="auto"
            width={{
              xs: '100%',
              md: '25%',
            }}
            minWidth={'340px'}
            sx={{
              '& .MuiTextField-root': { m: 1 },
              marginTop: { xs: '0', md: '10%' },
              padding: { xs: 2, md: 0 },
            }}
          >
            {login_callback.error&&<Alert severity='error'>{login_callback.error.message}</Alert>}
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
                mt: '5%',
                mb: '10%',
                borderBottom: 2,
                borderColor: 'secondary.main',
              }}
            >
              <Typography
                color="primary"
                fontWeight={'bold'}
                variant="h5"
                textAlign={'center'}
              >
                Leveraging Investor Values
              </Typography>
            </Box>
            <Typography
              color={'secondary'}
              fontSize={'1.2rem'}
              sx={{
                mb: 2,
              }}
            >
              LOGIN
            </Typography>

            <TextField
              label="Email"
              autoComplete="off"
              size="small"
              color="primary"
              focused
              fullWidth
              name="fundManager"
              variant="outlined"
              value={loginInput.email}
              onChange={(evt) => handleLoginInputchange('email', evt)}
            />
            <TextField
              label="Password"
              type="password"
              autoComplete="off"
              size="small"
              color="primary"
              focused
              fullWidth
              name="fundManager"
              variant="outlined"
              value={loginInput.password}
              onChange={(evt) => handleLoginInputchange('password', evt)}
            />

            <Stack>
              <FormControlLabel
                sx={{
                  mt: 2,
                }}
                control={
                  <Checkbox checked={checked} onChange={handleCheckboxChange} />
                }
                label={
                  <Typography>
                    By checking this box, you are agreeing to the Norsia{' '}
                    <Link
                      target="_blank"
                      color="inherit"
                      href="https://norsia.ch/terms-of-use/"
                    >
                      General Terms of Use
                    </Link>
                  </Typography>
                }
              ></FormControlLabel>
            </Stack>
            <Stack
              direction="column"
              justifyContent="end"
              alignContent={'end'}
              marginTop={2}
              spacing={1}
            >
              <LoadingButton
                color="secondary"
                variant="contained"
                sx={{
                  fontSize: '1em',
                  fontWeight: 'bold',
                  borderRadius: '4px',
                }}
                fullWidth
                onClick={handleLogin}
                loading={false /*login_callback.loading*/}
              >
                Login
              </LoadingButton>
              <Button
                variant="text"
                color="primary"
                sx={{ fontSize: { xs: '0.8em', md: '1em' } }}
                disableRipple
                onClick={() => changeWarningText('password')}
              >
                Forgot your password?
              </Button>

              <Button
                variant="text"
                color="primary"
                sx={{ fontSize: { xs: '0.8em', md: '1em' } }}
                disableRipple
                onClick={() => changeWarningText('account')}
              >
                Don't have an account?
              </Button>
              <Box
                height="5px"
                // my={4}
                py={2}
                position="relative"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {forgotPassword ? (
                  <Box
                    color="#F39200"
                    borderRadius="20px"
                    textAlign="center"
                    top={0}
                  >
                    If you don’t remember your password, please contact your
                    account manager at Norsia
                  </Box>
                ) : null}
                {createAccount ? (
                  <Box
                    color="#F39200"
                    borderRadius="20px"
                    textAlign="center"
                    top={0}
                  >
                    <Typography>
                      To create a professional account please contact us at{' '}
                      <Link href="mailto:info@norsia.ch">info@norsia.ch</Link>
                    </Typography>
                  </Box>
                ) : null}
                {acceptTerms ? (
                  <Box
                    color="#F39200"
                    borderRadius="20px"
                    textAlign="center"
                    top={0}
                    width={'100%'}
                  >
                    Please accept the Terms of Use
                  </Box>
                ) : null}
              </Box>
            </Stack>
          </Box>
          <Hidden mdDown>
            <Box
              sx={{
                width: { xs: '5%', lg: '10%' },
                borderBottom: 2,
                borderColor: 'secondary.main',
                marginTop: 'auto',
                marginBottom: '110px',
                height: 40,
              }}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography align="center" color={'primary'}>
            Change password
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Stack
            direction={'column'}
            justifyContent="space-between"
            spacing={0}
          >
            {update_password_callback.error&&<Alert severity='error'>
                {update_password_callback.error.message}</Alert>}
            <TextField
              label={'New password'}
              type={'password'}
              autoComplete="off"
              value={passwordChangeInput.new_password}
              onChange={(evt) =>
                handlePasswordUpdateInputChange('new_password', evt)
              }
            />
            <TextField
              label={'Confirm new password'}
              type={'password'}
              autoComplete="off"
              value={passwordChangeInput.confirm_new_password}
              onChange={(evt) =>
                handlePasswordUpdateInputChange('confirm_new_password', evt)
              }
            />
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ marginTop: 3 }}
              onClick={handlePasswordChange}
              loading={false /*update_password_callback.loading*/}
              disableRipple
            >
              Ok
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      <Box mt="auto">
        <Footer />
      </Box>
    </Box>
  );
}
