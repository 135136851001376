import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { NorsiaHeaderBox } from './resources/NorsiaCustomBoxes';

import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Alert, AlertTitle } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import create_report_initialvalues from './portfolioreport/resolvers/InitialValues';

import PortfolioReportResults from './portfolioreport/PortfolioReportResults';
import Context from '../../context';
import DragDrop from './portfolioreport/partials/FileDragDrop';
import * as XLSX from 'xlsx';

import { useQuery, useLazyQuery } from '@apollo/client';
import {
  GET_FUND_SEARCH_INPUT,
  GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE,
  GET_FUND_REPORT,
  GET_PORTFOLIO_REPORT,
} from '../graphql/queries';
import { useTheme } from '@emotion/react';

export default function PortfolioReport(props) {
  const [noResults, setNoResults] = React.useState(false);
  const theme = useTheme();
 

  theme.typography.h6 = {
    fontSize: '1.05rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.12rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  };

  const { state } = useContext(Context);
   const { ...get_fs_input } = useQuery(GET_FUND_SEARCH_INPUT,{fetchPolicy: "network-only" });
    const { ...get_ep_gql } = useQuery(GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE
        ,{variables:{enterprise_uuid:state.loginInfo.enterprise.uuid}},{fetchPolicy: "network-only" });
    const [get_fund_report,{...get_report}]=useLazyQuery(GET_FUND_REPORT,{fetchPolicy: "network-only" });
    const [get_portfolioreport,{...get_portfolio_report}]=useLazyQuery(GET_PORTFOLIO_REPORT,{fetchPolicy: "network-only" });
   
  const [report_type, set_report_type] = useState('');
  const [client_uuid, set_client_uuid] = useState('');
  const [open, setOpen] = useState(false);
  const [file_loading, set_file_loading] = useState(false);
  const [upload_filename, set_uploadfilename] = useState('');

  const [fund_report_variables, set_fund_report_variables] = useState({
    nor_isin: '',
    client_uuid: '',
  });

  const [eror_info, set_error_info] = useState({
    is_error: false,
    error_text: '',
  });

  const [portfolio_report_variables, set_portfolio_report_variables] = useState(
    {
      input: [],
      client_uuid: '',
    }
  );

  const [report_data, set_report_data] = useState(() => {
    return create_report_initialvalues();
  });

  useEffect(()=>{
    let currentUrl = window.location.href;
  
    if (!currentUrl.includes('ISIN=')) 
        return
     
    set_fund_report_variables({
      ...fund_report_variables,
      nor_isin: currentUrl.split('ISIN=')[1],
    });
  
    set_report_type('fund');
  
    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.split('?')[0];
      window.history.replaceState(null, null, currentUrl);
    }
  },[])
  

  const isin_onchange = (event) => {
    let currentUrl = window.location.href;
    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.split('?')[0];
      window.history.replaceState(null, null, currentUrl);
    }
  
    set_fund_report_variables({
      ...fund_report_variables,
      nor_isin: event.target.value,
    });

    set_report_type('fund');
  };
  const launch = () => {
   
    if (report_type === 'fund') {
      if (fund_report_variables.nor_isin.length !== 12) {
        alert('Fund ISIN length must be 12!');
        //set_error_info({...set_error_info,is_error:true,error_text:'Fund ISIN length must be 12!'})
        return;
      }
       
        get_fund_report({variables:{...fund_report_variables}})
            .then((response)=>{
            
                if(!response.data)
                {
                  setNoResults(true)
                    return;
                }
           
                resolve_reportdata({...response.data,report_type:report_type})
                setOpen(true);
            }).catch((error)=>{
             // console.log(error)
            })

            
    } else if (report_type === 'portfolio') {
       get_portfolioreport({variables:{...portfolio_report_variables}})
            .then((response)=>{
              if(!response.data)
              {
                setNoResults(true)
                  return;
              }
                resolve_reportdata({...response.data,report_type:report_type,upload_filename:upload_filename})
                setOpen(true);
              }).catch((error)=>{
              
              })        

      
    } else {
      throw Error('Invalid report type.');
    }
  };

  const handleClose = () => {
    setOpen(false);
    set_report_data(create_report_initialvalues());
  };

  const handleClientChange = (value) => {
    let { uuid } = value.client;

    set_fund_report_variables({ ...fund_report_variables, client_uuid: uuid });
    set_portfolio_report_variables({
      ...portfolio_report_variables,
      client_uuid: uuid,
    });
  };

  const formatClientName = (option) => {
    return `${option.client.name} ${option.client.surname}`;
  };


  const handleFileChange = async (e) => {
    set_file_loading(true);

    const reader = new FileReader();
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: '',
    });
    let input_data = [];
    jsonData.forEach((values) => {
      let [isin, weight] = values;

      if (typeof weight === 'number')
        input_data.push({ isin: isin, weight: weight });
    });

    set_portfolio_report_variables({
      ...portfolio_report_variables,
      input: input_data,
    });
    set_report_type('portfolio');
    set_uploadfilename(file.name);
    e.target.value = null;

    set_file_loading(false);
  };

  const resolve_reportdata = (raw_report_data) => {
    
    const { report_type } = raw_report_data;
    
    if (report_type === 'fund') {
      
      let {
        fund_name,
        fund_objective,
        customer_name,
        m1to7_bundled,
        fund_sustperf,
        eu_sustainable_label,
        exclusion_policy,
        positive_screening,
        sfdr_category,
        tags_theme,
        sustainable_tags,
        fund_controversy,
        asset_weights,
        portfolio_update_date
      } = raw_report_data.get_fund_report;
      set_report_data({
        ...report_data,
        ...resolve_metrics(m1to7_bundled),
        portfolio_name: fund_name,
        fund_objective:fund_objective,
        customer_name: customer_name,
        sustperformance: fund_sustperf,
        transparency: {
          tags_theme:tags_theme,
          sustainable_tags:sustainable_tags,
          eu_sustainable_label: eu_sustainable_label,
          sfdr_category: sfdr_category,
          exclusion_policy: exclusion_policy,
          positive_screening: positive_screening,
          sum_of_weights: asset_weights.clean_assets,
          portfolio_update_date:portfolio_update_date,
          total_weights: asset_weights.clean_assets+asset_weights.dirty_assets,
        },
        controversy: {
          coal: fund_controversy.sum_of_coal,
          fossil_fuels: fund_controversy.sum_of_fossilfuels,
          sum_of_climateaction100plus:fund_controversy.sum_of_climateaction100plus,
          sum_of_forest500laggard:fund_controversy.sum_of_forest500laggard,
          sum_of_wbahumanrightlaggard:fund_controversy.sum_of_wbahumanrightlaggard
        },
      });
    } else if (report_type === 'portfolio') {
      let { upload_filename, get_portfolioreport } = raw_report_data;
         set_report_data({
        ...report_data,
        ...resolve_metrics(get_portfolioreport.m1to7_bundled),
        portfolio_name: upload_filename,
        customer_name: get_portfolioreport.customer_name,
        sustperformance: get_portfolioreport.sustperformance,
        transparency: {
          sum_of_weights: get_portfolioreport.sum_of_weights,
          total_weights: get_portfolioreport.total_weights,
          portfolio_update_date:new Date().toDateString()

        },
        controversy: {
          coal: get_portfolioreport.controversy.sum_of_coal,
          fossil_fuels: get_portfolioreport.controversy.sum_of_fossilfuels,
          sum_of_climateaction100plus:get_portfolioreport.controversy.sum_of_climateaction100plus,
          sum_of_forest500laggard:get_portfolioreport.controversy.sum_of_forest500laggard,
          sum_of_wbahumanrightlaggard:get_portfolioreport.controversy.sum_of_wbahumanrightlaggard
        },
        portfolio_details:get_portfolioreport.m1to7_details
      });
    }
  };

  const resolve_metrics = (metrics) => {
    const { sum_of_pm_1, sum_of_pm_3, sum_of_pm_4, sum_of_pm_5, sum_of_pm_7 } =
      metrics;
    const esg_value = Math.round(sum_of_pm_1);
    const global_value = Math.round(sum_of_pm_7);

    const scoreMetricsBottomValues = [
      { letter: 'E', value: Math.round(sum_of_pm_3) },
      { letter: 'S', value: Math.round(sum_of_pm_4) },
      { letter: 'G', value: Math.round(sum_of_pm_5) },
    ];
    return {
      score_metrics_bottom_values: scoreMetricsBottomValues,
      esg_value: esg_value,
      global_value: global_value,
    };
  };

 

 

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%',
        maxWidth: '550px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NorsiaHeaderBox>
            <Typography variant="h6" color="primary">
              Fund/Portfolio report
            </Typography>
          </NorsiaHeaderBox>
        </Grid>
        <Grid item xs={12}>
          {eror_info.is_error && (
            <Alert severity="error">{eror_info.error_text}</Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack
            marginTop={2}
            direction="column"
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              label="ISIN"
              size="small"
              color="primary"
              focused
              fullWidth
              variant="outlined"
              value={fund_report_variables.nor_isin}
              onChange={isin_onchange}
            />

            <Typography variant="h6" color="primary">
              or
            </Typography>
            <DragDrop onChange={handleFileChange} />
            <Typography variant="h6" color="primary">
              for
            </Typography>
            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              options={
                get_ep_gql.data.enterprise_client_info_by_enterprise
              }
              getOptionLabel={(option) => formatClientName(option)}
              onChange={(event, value) => {
                handleClientChange(value);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Stack direction={'row'} spacing={2}>
                    <Typography color={'primary'}>
                      {formatClientName(option)}
                    </Typography>
                  </Stack>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Client/Strategy (Optional)"
                  {...params}
                  size="small"
                  color="primary"
                  focused
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 2,
                  }}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid container justifyContent={'right'}>
          <Button
            sx={{ width: 200, marginTop: 10, borderRadius: '4px' }}
            variant="contained"
            color="secondary"
            marginTop={10}
            onClick={launch}
          >
            Launch
          </Button>
        </Grid>
        
          {(get_fs_input.loading||get_ep_gql.loading||get_report.loading
        ||file_loading||get_portfolio_report.loading)&&
        (<Grid
          item
          xs={12}
          sx={{
            maxWidth: '100%',
            mb: 2,
          }}
        >
          <Stack
            sx={{
              border: '2px solid #003865',
              p: 1,
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
        <Typography>Loading...</Typography>
        </Stack>
          </Grid>)}
          
      </Grid>
      <Dialog
        fullWidth
        PaperProps={{
          maxWidth: 'auto',
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100%',
            height: '100%',
            '& .MuiPaper-root': {
              maxWidth: { xs: '100%', md: '900px' },
              maxHeight: { xs: '100%', md: 'auto' },
              width: '100%',
              margin: 0,
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogActions>
          <PortfolioReportResults {...report_data} close={handleClose} />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
