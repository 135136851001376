import {gql} from '@apollo/client';


export const LOGIN_MUTATION=gql`
    mutation($email: String!, $password: String!){
        login (
            input:{email:$email,
            password:$password}
        ){
          name,
          surname,
          uuid,
          is_temp_password,
          enterprise {
            name,
            uuid
          }
        }
    }
`

export const UPDATE_PASSWORD=gql`
    mutation($uuid:String!,$new_password:String!){
        update_password(input:{uuid:$uuid,new_password:$new_password})
    }
`

export const CREATE_ENTERPRISE_CLIENT=gql`
    mutation($enterprise_uuid:String!,$name:String!,$surname:String!,$email:String!,$is_survey_by_email:Boolean!){
        create_enterprise_client(input:{enterprise_uuid:$enterprise_uuid,
        name:$name,surname:$surname,email:$email,is_survey_by_email:$is_survey_by_email}){
            client{
                name
            },
            survey_link
           
        }
    }
`

export const DELETE_ENTERPRISE_CLIENT=gql`
    mutation($uuid:String!){
        delete_enterprise_client(uuid:$uuid)
    }

`