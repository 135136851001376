import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function MyClientsSvgIcon(props){
    return (
        <SvgIcon {...props} >
            <path d="M54.9999 43.3333C50.9999 43.3333 44.7666 44.4667 39.9999 46.6667C35.2332 44.4333 28.9999 43.3333 24.9999 43.3333C17.7666 43.3333 3.33325 46.9333 3.33325 54.1667V63.3333H76.6666V54.1667C76.6666 46.9333 62.2332 43.3333 54.9999 43.3333ZM41.6666 58.3333H8.33325V54.1667C8.33325 52.3667 16.8666 48.3333 24.9999 48.3333C33.1332 48.3333 41.6666 52.3667 41.6666 54.1667V58.3333ZM71.6666 58.3333H46.6666V54.1667C46.6666 52.6333 45.9999 51.3 44.9332 50.1C47.8666 49.1 51.4666 48.3333 54.9999 48.3333C63.1332 48.3333 71.6666 52.3667 71.6666 54.1667V58.3333ZM24.9999 40C31.4332 40 36.6666 34.7667 36.6666 28.3333C36.6666 21.9 31.4332 16.6667 24.9999 16.6667C18.5666 16.6667 13.3333 21.9 13.3333 28.3333C13.3333 34.7667 18.5666 40 24.9999 40ZM24.9999 21.6667C28.6666 21.6667 31.6666 24.6667 31.6666 28.3333C31.6666 32 28.6666 35 24.9999 35C21.3333 35 18.3333 32 18.3333 28.3333C18.3333 24.6667 21.3333 21.6667 24.9999 21.6667ZM54.9999 40C61.4332 40 66.6666 34.7667 66.6666 28.3333C66.6666 21.9 61.4332 16.6667 54.9999 16.6667C48.5666 16.6667 43.3332 21.9 43.3332 28.3333C43.3332 34.7667 48.5666 40 54.9999 40ZM54.9999 21.6667C58.6666 21.6667 61.6666 24.6667 61.6666 28.3333C61.6666 32 58.6666 35 54.9999 35C51.3332 35 48.3332 32 48.3332 28.3333C48.3332 24.6667 51.3332 21.6667 54.9999 21.6667Z" />
        </SvgIcon>
    );
}

export default MyClientsSvgIcon;


