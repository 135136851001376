export default function reducer(state,{type,payload}){ 
    switch(type){
        case 'LOGIN_USER':
            return{
                ...state,
                loginInfo:payload
            };
        case 'IS_LOGGED_IN':
            return{
                ...state,
                isAuth:payload
                };
        case 'SIGNOUT_USER':
            return{
                ...state,
                isAuth:false,
                loginInfo:null
            };
        case 'CREATE_FUNDSCREENER':
            return {
            ...state,
            screenerInfo:payload
        };
        case 'DELETE_FUNDSCREENER':
            return {
            ...state,
            screenerInfo:null
        };
    
        default:
            return state;

    }
}
