import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Context from '../../../context';
import { useMutation } from '@apollo/client/react';
import { CREATE_ENTERPRISE_CLIENT } from '../../graphql/mutations';
import Alert from '@mui/material/Alert';
import { dataTool } from 'echarts';
import { NorsiaHeaderBox } from '../resources/NorsiaCustomBoxes';
import { useTheme } from '@emotion/react';

export default function NewClient() {
  const theme = useTheme();

  theme.typography.h6 = {
    fontSize: '1.05rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.12rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  };

  const [create_enterprise_client, { loading, error }] = useMutation(
    CREATE_ENTERPRISE_CLIENT
  );
  const [success, setSuccess] = useState(false);
  const { state } = useContext(Context);

  const [client_input, set_client_input] = useState({
    enterprise_uuid: state.loginInfo.enterprise.uuid,
    name: '',
    surname: '',
    email: '',
  });

  const handleNewClientInputChange = (key, event) => {
    set_client_input({ ...client_input, [key]: event.target.value });
  };

  const profileOnThisDeviceClick = () => {
    setSuccess(false);
    const adj_input={...client_input,is_survey_by_email:false}
        create_enterprise_client({variables:adj_input})
        .then((response)=>{
            if(response.data.create_enterprise_client==null)
                throw new Error('Creating client failed');
            const {survey_link}=response.data.create_enterprise_client;

            if(survey_link==null)
                throw new Error('Survey link failed');

            window.open(survey_link,'_blank').focus();
            setSuccess(true);
        })
  };

  const profileOnEmailClick = () => {
    setSuccess(false);
    const adj_input = { ...client_input, is_survey_by_email: true };

    create_enterprise_client({ variables: adj_input }).then((response) => {
      if (response.data.create_enterprise_client == null)
        throw new Error('Creating client failed');
      const { survey_link } = response.data.create_enterprise_client;

      if (survey_link == null) throw new Error('Survey link failed');

      setSuccess(true);
    });
  };

  return (
    <Box
      sx={{
        marginLeft: '0%',
        marginRight: { xs: '3%', xl: '3%' },
      }}
    >
      <Grid
        container
        mt={6}
        spacing={2}
        direction="column"
        sx={{
          maxWidth: '550px',
        }}
      >
        <Box px="8px" marginLeft={2}>
          {success && <Alert severity="success">Created new client</Alert>}

          {error && <Alert severity="error">{error.message}</Alert>}
        </Box>
        <Stack
          //   direction={{ xs: 'column', lg: 'row' }}
          direction="column"
          justifyContent="space-between"
          alignItems={'center'}
          spacing={1}
          marginLeft={2}
        >
          <Box alignSelf="start" ml="8px">
            <NorsiaHeaderBox sx={{ width: '100%', maxWidth: '600px' }}>
              <Typography variant="h6" color="primary" alignSelf="start">
                Client Information
              </Typography>
            </NorsiaHeaderBox>
          </Box>
          <Box
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '& .MuiTextField-root': { m: 1 },
              width: '100%',
              maxWidth: '550px',
              boxSizing: 'border-box',
              //   marginRight: { xs: 2 },
              '& label': {
                color: '#003865',
              },
              input: { color: '#003865' },
            }}
          >
            <TextField
              required
              label="First name"
              size="small"
              value={client_input.name}
              onChange={(evt) => handleNewClientInputChange('name', evt)}
            />
            <TextField
              required
              label="Last name"
              size="small"
              value={client_input.surname}
              onChange={(evt) => handleNewClientInputChange('surname', evt)}
            />
            <TextField
              required
              label="Email"
              size="small"
              value={client_input.email}
              onChange={(evt) => handleNewClientInputChange('email', evt)}
            />
          </Box>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            display="flex"
            // direction={{ xs: 'column' }}
            spacing={2}
            px="8px"
            // sx={{ width: { xs: '90%', md: 1 }, maxWidth: '300px' }}
            width="100%"
          >
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ fontSize: '1em', flex: 1 }}
              onClick={profileOnThisDeviceClick}
              loading={loading}
              fullWidth
            >
              Profile on this Device
            </LoadingButton>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ fontSize: '1em', flex: 1 }}
              onClick={profileOnEmailClick}
              loading={loading}
              fullWidth
            >
              Profile on Email Invitation
            </LoadingButton>
          </Stack>
        </Stack>
      </Grid>
    </Box>
  );
}
