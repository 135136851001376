import * as React from 'react';

function SvgTobacco(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 512 640"
      {...props}
    >
      <path d="M25.1 382.045h443.518a11 11 0 0011-11v-70.5a11 11 0 00-11-11H25.1a11 11 0 00-11 11v70.5a11 11 0 0011 11zm113.547-22v-48.5h267.7v48.5zm318.971 0h-29.274v-48.5h29.274zM36.1 311.544h80.547v48.5H36.1zM472.263 201.2a11 11 0 10-22 0c0 19.109 8.562 29.17 15.442 37.253 5.919 6.955 10.195 11.98 10.195 22.994a11 11 0 0022 0c0-19.109-8.562-29.169-15.442-37.253-5.919-6.953-10.195-11.978-10.195-22.994z" />
      <path d="M435.9 261.448a11 11 0 0022 0c0-19.109-8.562-29.169-15.442-37.253-5.919-6.954-10.2-11.979-10.2-22.994s4.276-16.039 10.2-22.993c6.88-8.084 15.442-18.145 15.442-37.253a11 11 0 00-22 0c0 11.014-4.276 16.039-10.2 22.994-6.879 8.083-15.441 18.143-15.441 37.252s8.562 29.17 15.442 37.253c5.923 6.955 10.199 11.98 10.199 22.994zM395.9 261.448a11 11 0 0022 0c0-19.109-8.562-29.169-15.442-37.253-5.919-6.954-10.2-11.979-10.2-22.994a11 11 0 10-22 0c0 19.109 8.562 29.17 15.442 37.253 5.924 6.955 10.2 11.98 10.2 22.994z" />
    </svg>
  );
}

export default SvgTobacco;
