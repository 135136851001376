import React, { useEffect, useState } from 'react';

import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { borderColor, Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';

const TransparencyAnalyticsBox = styled(Box)({
  borderRadius: 5,
  height: 33,
  display: 'flex',
  flexDirection: 'row', // Change to 'row' to align label and value horizontally
  alignItems: 'center', // Align items at the center of the flex container
  justifyContent: 'space-between', // Add space between label and value
});

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  textAlign: 'right',
  fontSize: 9,
  fontWeight: 'bold',
  flex: 1, // Allow the label to occupy all available space
}));

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.dark,
  textAlign: 'left',
  fontSize: 9,
  fontWeight: 'bold',
  flex: 2, // Allow the value to occupy twice the space of the label
}));

const TextValue = (props) => {
  const { children } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {

  }, [showTooltip]);

  const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.dark,
    textAlign: 'left',
    fontSize: 9,
    fontWeight: 'bold',
  }));

  if (children === undefined || children===null) {
    return <Text>{''}</Text>;
  } 
  else if(!isNaN(children)){
    const value=parseInt(children)
    return value>=0?  <Text>{`${value}`}</Text>:<Text>{''}</Text>
  }
  else if (children.length < 20) {
    return <Text>{children}</Text>;
  } else {
    return (
      <Tooltip
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        title={
          <React.Fragment>
            <Typography fontSize={10} color={'white'}>
              {children}
            </Typography>
          </React.Fragment>
        }
      >
        <Stack>
          <Link
            display={{
              xs: 'none',
              sm: 'flex',
              md: 'none',
            }}
            underline="hover"
            onClick={() => setShowTooltip(!showTooltip)}
            fontSize={9}
            sx={{
              cursor: 'default',
            }}
            fontWeight={'bold'}
            color={'#000000'}
          >
            <Stack><Text>{`${children.substring(0, 160)}...`}</Text></Stack>
          </Link>

          <Link
            display={{
              xs: 'flex',
              sm: 'none',
              md: 'flex',
            }}
            underline="hover"
            onClick={() => setShowTooltip(!showTooltip)}
            fontSize={9}
            sx={{
              cursor: 'default',
            }}
            fontWeight={'bold'}
            color={'#000000'}
          >
            <Stack><Text>{`${children.substring(0, 20)}...`}</Text></Stack>
          </Link>
        </Stack>
      </Tooltip>
    );
  }
};

export default function Transparencyanalytics(props) {

  const {
    eu_sustainable_label,
    sfdr_category,
    exclusion_policy,
    positive_screening,
    sum_of_weights,
    total_weights,
    tags_theme,
    sustainable_tags,
    portfolio_update_date
  } = props;


  return (
    <Stack
      container
      direction={'row'}
      boxSizing={'border-box'}
      height={'87%'}
      pb={0}
      mr="auto"
      ml={{
        xs: 'auto',
        sm: 6,
        md: 'auto',
      }}
    >
      <Grid
        container
        sx={{
          height: '100%',
          mt: 1,
        }}
      >
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>European sustainable label</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>{eu_sustainable_label}</TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>SFDR category</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>{sfdr_category}</TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Exclusions policy</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>{exclusion_policy}</TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Positive screening</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>
              {
                positive_screening
              }
            </TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Nb of sustainable tags</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value><TextValue>{sustainable_tags}</TextValue></Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Sustainable tags theme</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>{tags_theme}</TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Holdings published</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>
              {total_weights === undefined ? '' : `${total_weights}%`}
            </TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>ESG-rated holdings</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>
              {sum_of_weights === undefined ? '' : `${sum_of_weights}%`}
            </TextValue>
          </Value>
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={5}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Label>Holding Confidence</Label>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <div
            style={{
              width: '3px',
              height: '100%',
              backgroundColor: '#003865',

              mx: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            py: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Value>
            <TextValue>{portfolio_update_date}</TextValue>
          </Value>
        </Grid>
      </Grid>
    </Stack>
  );
}
