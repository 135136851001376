import React, {  useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NorsiaHeaderBox } from './resources/NorsiaCustomBoxes';
import combobox_values from '../../data/fundscreener_combobox.json'
import Context from '../../context';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { useTheme } from '@emotion/react';
import SpreadSheet from './FundsScreener/SpreadSheet';
import { makeStyles } from '@mui/styles';
import {GET_FUNDSCREENER_DATA} from '../graphql/queries';
import { useLazyQuery } from '@apollo/client';



const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
    },
  },
  customTooltip: {
    fontSize: '14px',
    color: '#fff',
    borderRadius: '4px',
    padding: '8px 12px',
    margin: 0,
  },
}));

const AndORTooltip = ({ and }) => {
  const text =
    and == 'AND'
      ? 'Multiple selections for this field are considered required combination.'
      : 'Multiple selections for this field are considered alternatives.';

  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.customTooltip }}
      title={text}
      placement="bottom"
    >
      {and == 'AND' ? (
        <img
          style={{
            height: '20px',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          src="/AND.png"
        ></img>
      ) : (
        <img
          style={{
            height: '20px',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          src="/OR.png"
        ></img>
      )}
    </Tooltip>
  );
};

const CreateMenuItems=(combobox_items)=>{
   return combobox_items.map((item)=>
      <MenuItem value={item}>{item}</MenuItem>
   );
}

export default function FundsScreener(props) {
  const { state,dispatch } = useContext(Context);

  const [get_fundscreener_data,{loading}]=useLazyQuery(GET_FUNDSCREENER_DATA
    ,{fetchPolicy: "network-only" });
  const classes = useStyles();
  
  const [formValues, setFormValues] = React.useState(
  ()=>{
    
    if (state.screenerInfo!==null)
      {
        return state.screenerInfo.filters
      }
    
    return{
        tr_fundname: '',
        tr_fundcompany: [],
        tr_fundtype: [],
        tr_fundcurrency: [],
        tr_fundgeographicfocus: [],
        tr_fundincdistributionindicator: [],
        tr_funddomicile: [],
        tr_fundlegalstructure: [],
        nor_sfdrarticle: [],
        sustainability_label: [],
        exclusion_policy: [],
        nor_sustainabletag:[],
        nor_theme:[]
    }
});

  const [filterValues, setFilterValues] = React.useState(()=>{
    if (state.screenerInfo!==null){
      return state.screenerInfo.data
    }
    return []
  });
  const [noResults, setNoResults] = React.useState(false);

  const [sustainabilityLabelORAND, setSustainabilityLabelORAND] =
    React.useState('OR');
  const [exclusionPolicyORAND, setExclusionPolicyORAND] = React.useState('AND');
  const [sustainableTagsThemeORAND, setSustainableTagsThemeORAND] =
    React.useState('OR');

    const handleFilter = () => { 
      setFilterValues([]);
      get_fundscreener_data({variables:formValues})
      .then((response)=>{
        if(response.data===undefined){
            setNoResults(true);
            return;
          }
        
          if (Object.keys(response.data.get_fundscreener_data).length === 0) {
             
            setNoResults(true);
            return
          } else {
            setNoResults(false);
          }
          dispatch({type:'CREATE_FUNDSCREENER',payload:{filters:formValues,data:response.data.get_fundscreener_data}})
          setFilterValues(response.data.get_fundscreener_data); 
          return;
        }
      ).catch(error=>{

      }).finally()
      
    };

  const [open, setOpen] = React.useState(false);

  const [sustainabilityLabel, setSustainabilityLabel] = React.useState([]);
  const [exclusionPolicy, setExclusionPolicy] = React.useState([]);
  const [minimumTags, setMinimumTags] = React.useState(0);

  const theme = useTheme();
  
  const resetFilters = () => {
    setFormValues({
      tr_fundname: '',
      tr_fundcompany: [],
      tr_fundtype: [],
      tr_fundcurrency: [],
      tr_fundgeographicfocus: [],
      tr_fundincdistributionindicator: [],
      tr_funddomicile: [],
      tr_fundlegalstructure: [],
      nor_sfdrarticle: [],
      sustainability_label: [],
      exclusion_policy: [],
      nor_sustainabletag:[],
      nor_theme:[]
    });
    setFilterValues([]);
    setSustainabilityLabel([]);
    setExclusionPolicy([]);
    setMinimumTags([]);
    setNoResults(false);
    dispatch({type:'DELETE_FUNDSCREENER'})
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSustaibility = (e) => {
    const index = sustainabilityLabel.indexOf(e.target.value);

    if (index === -1) {
      setSustainabilityLabel([...sustainabilityLabel, e.target.value]);
    } else {
      setSustainabilityLabel(
        sustainabilityLabel.filter((label) => label !== e.target.value)
      );
    }
  };

  const handleExclusionPolicy = (e) => {
    const index = exclusionPolicy.indexOf(e.target.value);

    if (index === -1) {
      setExclusionPolicy([...exclusionPolicy, e.target.value]);
    } else {
      setExclusionPolicy(
        exclusionPolicy.filter((label) => label !== e.target.value)
      );
    }
  };

  const handleSlider = (e) => {
    setMinimumTags(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        marginTop: '2%',
        mx: {
          xs: '2%',
          md: '5%',
        },
        width: {
          xs: '100%',
          md: '65vw',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '550px',
        }}
      >
        <NorsiaHeaderBox>
          <Typography variant="h6" color="primary">
            Sustainable Fund Screener
          </Typography>
        </NorsiaHeaderBox>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={12} md={6}>
          <Stack marginTop={2} direction="column" spacing={{ xs: 3, md: 4 }}>
            <Typography
              fontSize={18}
              color={'primary.main'}
              fontWeight={'bold'}
            >
              Fund general characteristics
            </Typography>

            <TextField
              label="Fund Name"
              size="small"
              color="primary"
              focused
              fullWidth
              name="tr_fundname"
              variant="outlined"
              value={formValues.tr_fundname}
              onChange={handleChange}
            />
            <Box display={'flex'} gap={1}>
              <TextField
                label="Fund Manager"
                name="tr_fundcompany"
                size="small"
                color="primary"
                focused
                select
                fullWidth
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundcompany,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundcompany)}
                
              </TextField>
              <AndORTooltip />
            </Box>
<Box display={'flex'} gap={1}>
              <TextField
                label="Type"
                name="tr_fundtype"
                size="small"
                color="primary"
                focused
                select
                fullWidth
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundtype,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundtype)}
                
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                label="Currency"
                name="tr_fundcurrency"
                size="small"
                color="primary"
                focused
                fullWidth
                select
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundcurrency,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundcurrency)}
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                label="Geographic Focus"
                name="tr_fundgeographicfocus"
                size="small"
                color="primary"
                select
                focused
                fullWidth
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundgeographicfocus,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundgeographicfocus)}
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                label="Distribution Policy"
                name="tr_fundincdistributionindicator"
                size="small"
                color="primary"
                select
                focused
                fullWidth
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundincdistributionindicator,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundincdistributionindicator)}
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                label="Domicile"
                select
                size="small"
                color="primary"
                focused
                fullWidth
                name="tr_funddomicile"
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_funddomicile,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_funddomicile)}
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                label="Legal Structure"
                size="small"
                color="primary"
                focused
                fullWidth
                select
                name="tr_fundlegalstructure"
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: formValues.tr_fundlegalstructure,
                  onChange: handleChange,
                }}
              >
                {CreateMenuItems(combobox_values.tr_fundlegalstructure)}
              </TextField>
              <AndORTooltip />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            marginTop={2}
            direction="column"
            spacing={{ xs: 3, md: 4 }}
            flexGrow={1}
            height={'100%'}
          >
            <Typography
              fontSize={18}
              color={'primary.main'}
              fontWeight={'bold'}
            >
              Fund Sustainability Characteristics
            </Typography>
            <Box display={'flex'} gap={1}>
              <TextField
                label="SFDR Article"
                select
                size="small"
                color="primary"
                focused
                fullWidth
                name="nor_sfdrarticle"
                SelectProps={{
                  multiple: true,
                  value: formValues.nor_sfdrarticle,
                  onChange: handleChange,
                }}
                variant="outlined"
                // value={formValues.SFDRArticle}
                // onChange={handleChange}
              >
                <MenuItem value="-">-</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                sx={{
                  flex: 1,
                }}
                label="Sustainability Label"
                select
                size="small"
                color="primary"
                focused
                name="sustainability_label"
                SelectProps={{
                  multiple: true,
                  value: formValues.sustainability_label,
                  onChange: handleChange,
                }}
                variant="outlined"
              >
                <MenuItem value="nor_isr">ISR</MenuItem>
              <MenuItem value="nor_towardsustainability">
                Towardsustainability
              </MenuItem>
              <MenuItem value="nor_luxflag">LuxFlag</MenuItem>
              <MenuItem value="nor_fng_siegel">FNG-Siegel</MenuItem>
              <MenuItem value="nor_greenfin">GreenFin</MenuItem>
              <MenuItem value="nor_austrian">Umweltzeichnen</MenuItem>
              <MenuItem value="nor_nordicswan">Nordic Swan</MenuItem>
              <MenuItem value="nor_finansol">Finansol</MenuItem>
              </TextField>
              <AndORTooltip />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                sx={{
                  flex: 1,
                }}
                label="Exclusion Policy"
                select
                size="small"
                color="primary"
                focused
                fullWidth
                name="exclusion_policy"
                SelectProps={{
                  multiple: true,
                  value: formValues.exclusion_policy,
                  onChange: handleChange,
                }}
                variant="outlined"
              >
                <MenuItem value="nor_controversalweapons">
                Controversal Weapons
              </MenuItem>
              <MenuItem value="nor_weapons">Weapons</MenuItem>
              <MenuItem value="nor_alcohol">Alcohol</MenuItem>
              <MenuItem value="nor_tobacco">Tobacco</MenuItem>
              <MenuItem value="nor_nuclearpower">Nuclear</MenuItem>
              <MenuItem value="nor_coal">Coal</MenuItem>
              <MenuItem value="nor_gambling"> Gambling</MenuItem>
              <MenuItem value="nor_animaltesting">Animal Testing</MenuItem>
              <MenuItem value="nor_geneticengineering">
                Genetic Engineering
              </MenuItem>
              </TextField>
              <AndORTooltip and="AND" />
            </Box>
            <Box display={'flex'} gap={1}>
              <TextField
                sx={{
                  flex: 1,
                }}
                label="Sustainable Tags Theme"
                select
                size="small"
                color="primary"
                focused
                fullWidth
                name="nor_sustainabletag"
                SelectProps={{
                  multiple: true,
                  value: formValues.nor_sustainabletag,
                  onChange: handleChange,
                }}
                variant="outlined"
              >
                <MenuItem value="Environmental Innovation">
                  Environmental Innovation
                </MenuItem>
                <MenuItem value="Resources Use">Resources Use</MenuItem>
                <MenuItem value="Emissions">Emissions</MenuItem>
                <MenuItem value="Product Responsibility">
                  Product Responsibility
                </MenuItem>
                <MenuItem value="Community">Community</MenuItem>
                <MenuItem value="Sustainable Strategy">
                  Sustainable Strategy
                </MenuItem>
                <MenuItem value="Working Conditions">
                  Working Conditions
                </MenuItem>
                <MenuItem value="Animal Testing">Animal Testing</MenuItem>
                <MenuItem value="Management">Management</MenuItem>
              </TextField>
              <AndORTooltip />
            </Box>
            <TextField
              sx={{
                flex: 1,
              }}
              label="Minimum number of Sustainable Tags"
              select
              size="small"
              color="primary"
              focused
              fullWidth
              name="nor_theme"
              SelectProps={{
                multiple: false,
                value: formValues.nor_theme,
                onChange: handleChange,
              }}
              variant="outlined"
            >
              <MenuItem value="0">0</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6"> 6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
            </TextField>

            <Box
              width={'100%'}
              display={'flex'}
              alignSelf={'end'}
              justifyContent={'end'}
              className="find_button"
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#F39200',
                  width: '10rem',
                  height: '3rem',
                  borderRadius: '4px',
                }}
                onClick={handleFilter}
              >
                Find
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box height={'25px'} />
        </Grid>
        {noResults ? (
          <Grid
            item
            xs={12}
            sx={{
              maxWidth: '100%',
              mb: 2,
            }}
          >
            <Stack
              sx={{
                border: '2px solid #003865',
                p: 1,
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography>No results</Typography>

              <Typography
                color={'secondary.main'}
                sx={{
                  borderBottom: '1px solid white',
                  '&:hover': {
                    borderBottom: '1px solid #F39200',
                  },
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
                onClick={resetFilters}
              >
                Reset criteria
              </Typography>
            </Stack>
          </Grid>
        ) : loading?
        (<Grid
          item
          xs={12}
          sx={{
            maxWidth: '100%',
            mb: 2,
          }}
        >
          <Stack
            sx={{
              border: '2px solid #003865',
              p: 1,
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
        <Typography>Loading...</Typography>
        </Stack>
          </Grid>):null}
        {filterValues.length > 0 ? (
          <Grid
            item
            xs={12}
            sx={{
              maxWidth: '100%',
            }}
          >
            <SpreadSheet
              setValue={props.setValue}
              filterValues={filterValues}
              resetFilters={resetFilters}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
