import * as React from 'react';
import { Grid,Box,Stack, Typography } from '@mui/material';
import ReportHeader from './partials/ReportHeader';


const format_value=(value)=>{
    switch(value){
        case 'no': return 'NO';
        case '1-25%': return 'YES, BETWEEN 1-25%';
        case '25-50%': return 'YES, BETWEEN 25-50%';
        case '50-75%': return 'YES, BETWEEN 50-75%';
        case '>75%': return 'YES, MORE THAN 75%';
        case 'N/A': return 'N/A';
        default: throw Error('Underined value');
    }
}

const MifidTypographyRegular=(props)=>{
    const {children}=props;

    return(
    <Typography sx={{fontSize:12,color:'primary.main'}}>
        {children}
    </Typography>)
}

const mifid_description=(which)=>{
    if(which=== 'first'){
            return (
            <MifidTypographyRegular>
                Is there a minimum amount of capital to be invested in <b>sustainable investments?</b> 
            </MifidTypographyRegular>
            )  
       
    }
    else if(which=== 'second'){
        return (
                <MifidTypographyRegular>
                    Is there a minimum amount of capital to be invested in environmentally <b>sustainable investments?</b>
                </MifidTypographyRegular>
        )
    }
    else if(which=== 'third'){
        return (
            <MifidTypographyRegular>
                Is there a minimum amount of capital to be invested in <b>investments 
                that consider their potential negative impacts on sustainability factors?</b>
            </MifidTypographyRegular>
        )
    }
}

const MifidSection=(props)=>{
    const {size,mifid_description_type,mifid_value}=props;
    return (
        <Grid container>
                                <Grid item xs={8}
                                    >
                                        {mifid_description(mifid_description_type)}
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={12-size}></Grid>
                                <Grid item xs={size} sx={{borderBottom:2,borderColor:'secondary.main'}}>

                                    <Typography sx={{fontSize:12,color:'primary.main',
                                        fontWeight:'bold',justifyContent:'flex-end',
                                        display:'flex'}}>
                                            {format_value(mifid_value)}
                                    </Typography>
                                </Grid>
                        </Grid>
    )
}

export default function InvestorMifidResults(props){
    const {mifid,customer_name}=props;
   
    return (
        <Grid container spacing={0.5} >
            <Grid item xs={12}>
                <ReportHeader report_name={'INVESTOR SUSTAINABILITY PREFERENCES'} customerName={customer_name}/>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{width:1,bgcolor:'primary.main',color:'white',padding:2}}>
                    <Typography fontSize={12} >
                        {customer_name} has not expressed a clear interest in sustainable development
                        and/or has not responded to Norsia's profiling questionnaire. 
                        Therefore, the investor profile below only highlights the investor's
                        sustainability preferences as required by 
                        European regulations.
                    </Typography>
                </Box>
                
            </Grid>
            <Grid item xs={12} >
                <Box sx={{width:1,border:2,borderColor:'primary.main',borderRadius:0.5,padding:2}}>
                    <Stack  direction={'column'} spacing={4}>
                        <MifidSection
                            size={4}
                            mifid_value={mifid.mifid_rdo_1}
                            mifid_description_type={'first'}
                        />
                        <MifidSection
                            size={6}
                            mifid_value={mifid.mifid_rdo_2}
                            mifid_description_type={'second'}
                        />
                        <MifidSection
                            size={10}
                            mifid_value={mifid.mifid_rdo_3}
                            mifid_description_type={'third'}
                        />
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}