import ArrowDownSvgIcon from './ArrowDown';
import CashLockSvgIcon from './CashLock';
import FundScreenerSvgicon from './FundsScreener';
import MyClientsSvgIcon from './MyClients';
import PortfolioReportSvgIcon from './PortfolioReport';
 import SettingsSvgIcon from './Settings';
 import StrategiesSvgIcon from './Strategies';
 import FactorySvgIcon from './exclusions/Factory';
 import HomeSvgIcon from './Home';
 import SvgAlcohol from '../icons/exclusions/Alcohol';
 import SvgAnimalTesting from '../icons/exclusions/AnimalTesting';
 import SvgCoal from '../icons/exclusions/Coal';
 import SvgFossilFuel from '../icons/exclusions/FossilFuel';
 import SvgGambling from '../icons/exclusions/Gambling';
 import SvgGmo from '../icons/exclusions/Gmo';
 import SvgNuclear from '../icons/exclusions/Nuclear';
 import SvgTobacco from '../icons/exclusions/Tobacco';
 import SvgWeapon from '../icons/exclusions/Weapon';
 import FactoryIcon from '@mui/icons-material/Factory';

 const CustomSvgIcon=(props)=>{
     const {name,...other}=props;
    
     switch(name){
         case 'Home':return <HomeSvgIcon {...other}/>;
         case 'MyClients': return <MyClientsSvgIcon {...other}/>;
         case 'CompaniesScreener':return <CashLockSvgIcon {...other}/>;
         case 'FundsScreener':return <FundScreenerSvgicon {...other}/>;
         case 'PortfolioReport':return <PortfolioReportSvgIcon {...other}/>;
         case 'Settings':return <SettingsSvgIcon {...other}/>;
         case 'Strategies':return <StrategiesSvgIcon {...other}/>;
         case 'ArrowDown':return <ArrowDownSvgIcon {...other}/>;
         case 'Alcohol':return <SvgAlcohol {...other}/>;
         case 'AnimalTesting':return <SvgAnimalTesting {...other}/>;
         case 'Coal':return <SvgCoal {...other}/>;
         case 'FossilFuel':return <SvgFossilFuel {...other}/>;
         case 'Gambling':return <SvgGambling {...other}/>;
         case 'Gmo':return <SvgGmo {...other}/>;
         case 'GMO':return <SvgGmo {...other}/>;
         case 'Nuclear':return <SvgNuclear {...other}/>;
         case 'Tobacco':return <SvgTobacco {...other}/>;
         case 'Weapon':return <SvgWeapon {...other}/>;
         case 'Factory':return <FactorySvgIcon {...other}/>
         default: console.log(name); throw new Error('Unknown icon name.');
     }
 }

 export {CustomSvgIcon};
