import {gql} from '@apollo/client';


export const GET_ENTERPRISE_CLIENTS_BY_ENTERPRISE=gql`query($enterprise_uuid:String!){
    enterprise_client_info_by_enterprise(enterprise_uuid:$enterprise_uuid) 
            { 
                client{
                    uuid,
                    name,
                    surname
                },
                survey_link
            }
      }
`;

export const GET_EPA=gql`
      query($uuid:String!){
        get_epa(uuid: $uuid) {
            thematics {
              cls_sdev,
              cls_sum,
              x_coord,
              y_coord  
            },
            profile {
              category,
              val
            },
           bubblechart {
             category,
             x_coord,
             y_coord,
             color,
              border_color,
              bubble_size
           },
           wfesg{
            env_min,
            env_max,
            env_avg,
            env_inv,
            soc_min,
            soc_max,
            soc_avg,
            soc_inv,
            gvn_min,
            gvn_max,
            gvn_avg,
            gvn_inv,
           },
            exclusions {
              sector
            },
            mifid {
              mifid_rdo_1,
              mifid_rdo_2,
              mifid_rdo_3,
              mifid_slider_1,
              mifid_slider_2,
              
            }
          }
      }
`;

export const GET_FUND_SEARCH_INPUT=gql`
      query{
        get_fundsearch_input {
          id,
          nor_isin,
          tr_fundname
        }
      }

`;

export const GET_FUND_REPORT=gql`
      query($nor_isin:String!,$client_uuid:String!){
        get_fund_report(nor_isin:$nor_isin
          ,client_uuid:$client_uuid){
            customer_name,
            fund_name,
            fund_objective,
            eu_sustainable_label,
            sfdr_category,
            exclusion_policy,
            positive_screening,
            tags_theme,
            sustainable_tags,
            m1to7_bundled {
              sum_of_pm_1,
              sum_of_pm_2,
              sum_of_pm_3,
              sum_of_pm_4,
              sum_of_pm_5,
              sum_of_pm_6,
              sum_of_pm_7
            },
            fund_controversy {
              sum_of_coal,
              sum_of_fossilfuels,
              sum_of_climateaction100plus,
              sum_of_forest500laggard,
              sum_of_wbahumanrightlaggard
            },
            fund_sustperf {
              very_strong,
              strong,
              moderate,
              poor
            },
            asset_weights{
              clean_assets,
              dirty_assets
            },
            portfolio_update_date
          }
      }
`;

export const GET_PORTFOLIO_REPORT=gql`
      query($client_uuid:String!,$input:[portfolio_item]){
        get_portfolioreport(client_uuid:$client_uuid,input:$input){
          customer_name,
          m1to7_bundled {
            sum_of_pm_1,
            sum_of_pm_2,
            sum_of_pm_3,
            sum_of_pm_4,
            sum_of_pm_5,
            sum_of_pm_6,
            sum_of_pm_7
          },
          sum_of_weights,
          total_weights,
          controversy {
            sum_of_coal,
            sum_of_fossilfuels,
            sum_of_climateaction100plus,
            sum_of_forest500laggard,
            sum_of_wbahumanrightlaggard
          },
          sustperformance { 
            very_strong,
            strong,
            moderate,
            poor 
          },
          m1to7_details{
            isin,
            weight,
            company_name,
            pm_1,
            pm_7,
            pm_3,
            pm_4,
            pm_5,
            nor_coal,
            nor_fossilfuels
          } 
        }
      }
`;

export const GET_FUNDSCREENER_DATA=gql`
    query($tr_fundname: String,
      $tr_fundcompany: [String],
      $tr_fundtype: [String],
      $tr_fundcurrency: [String],
      $tr_fundgeographicfocus: [String],
      $tr_fundincdistributionindicator: [String], 
      $tr_funddomicile: [String],
      $tr_fundlegalstructure: [String],
      $nor_sfdrarticle: [String],
      $sustainability_label: [String],
      $exclusion_policy: [String],
      $nor_sustainabletag:[String],
      $nor_theme:[String]
      ){
      get_fundscreener_data(input:{
        tr_fundname:$tr_fundname,
        tr_fundcompany: $tr_fundcompany,
        tr_fundtype: $tr_fundtype,
        tr_fundcurrency: $tr_fundcurrency,
        tr_fundgeographicfocus: $tr_fundgeographicfocus,
        tr_fundincdistributionindicator: $tr_fundincdistributionindicator,
        tr_funddomicile: $tr_funddomicile,
        tr_fundlegalstructure: $tr_fundlegalstructure,
        nor_sfdrarticle: $nor_sfdrarticle,
        sustainability_label: $sustainability_label,
        exclusion_policy: $exclusion_policy ,
        nor_sustainabletag:$nor_sustainabletag,
        nor_theme:$nor_theme
      }){
        nor_isin,
        tr_fundname,
        tr_fundcompany,
        tr_fundtype,
        tr_fundobjective,
        tr_fundcurrency,
        tr_fundgeographicfocus,
        tr_fundincdistributionindicator,
        tr_funddomicile,
        tr_fundter,
        tr_fundlegalstructure,
        nor_sfdrarticle,
        nor_isr,
        nor_towardsustainability,
        nor_luxflag,
        nor_fng_siegel,
        nor_greenfin,
        nor_austrian,
        nor_nordicswan,
        nor_finansol,
        nor_controversalweapons,
        nor_weapons,
        nor_alcohol,
        nor_tobacco,
        nor_nuclearpower,
        nor_coal,
        nor_gambling,
        nor_animaltesting,
        nor_geneticengineering,
        nor_sustainabletag,
        nor_theme
      }
    }
`;