import React from 'react';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import ForestIcon from '@mui/icons-material/Forest';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { Box, Stack, Typography } from '@mui/material';
import Co2Icon from '@mui/icons-material/Co2';


const formatValue=(value)=>{
    return `${value}%`
}

const SustainabilityChecks = (props) => {
  const {sum_of_climateaction100plus,
        sum_of_forest500laggard,
        sum_of_wbahumanrightlaggard}=props;
 
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      px={2}
      alignContent={'center'}
      justifyContent={'center'}
      gap={1.5}
      height={'85%'}
      maxWidth={'530px'}
      mx={'auto'}
      mt={{
        xs: 1.5,
        md: 0,
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <FilterDramaIcon
            sx={{
              color: '#003865',
            }}
            fontSize="medium"
          />
          <Co2Icon
            sx={{
              color: '#003865',
              bottom: '-5px',
              right: '-10px',
              position: 'absolute',
            }}
            fontSize="small"
          />
        </Box>
        <Typography color={'primary.main'} fontSize={'10.5px'}>
          <span style={{ fontWeight: 'bold' }}>{formatValue(sum_of_climateaction100plus)}</span> of capital is
          supporting companies that are considered the world's largest CO2
          emitters
        </Typography>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ForestIcon
          sx={{
            color: '#003865',
          }}
          fontSize="medium"
        />
        <Typography color={'primary.main'} fontSize={'10.5px'}>
          <span style={{ fontWeight: 'bold' }}>{formatValue(sum_of_forest500laggard)}</span> of capital supports
          companies with poor deforestation commitments and policies, even
          though they are causing it
        </Typography>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EmojiPeopleIcon
          sx={{
            color: '#003865',
          }}
          fontSize="medium"
        />
        <Typography color={'primary.main'} fontSize={'10.5px'}>
          <span style={{ fontWeight: 'bold' }}>{formatValue(sum_of_wbahumanrightlaggard)}</span> of capital is
          supporting companies with poor human rights related commitments and
          policies
        </Typography>
      </Stack>
    </Box>
  );
};

export default SustainabilityChecks;
