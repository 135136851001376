/*import React,{useState} from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Input from '@mui/material/Input';
import * as XLSX from 'xlsx';

const fileTypes=['XLSX']

function DragDrop() {
    const [file, setFile] = useState(null);

    const handleChange = async (e) => {
      //setFile(file);
      const reader=new FileReader();
      const file=e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
        });
        console.log(jsonData);
        e.target.value=null;
    };

    const hiddenFileInput = React.useRef(null);

   
    return (
        <input
        fullWidth
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        hidden
        />
    )
  }
  
  
  export default DragDrop;
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography } from '@mui/material';

export default function DragDrop(props) {
  return (
    <Stack direction="row" alignItems="center" spacing={2} width={'100%'}>
      <Button
        variant="contained"
        component="label"
        sx={{ width: '100%', borderRadius: '4px' }}
      >
        <FileUploadIcon />
        <Typography>Customized portfolio as .xlsx</Typography>
        <input hidden multiple type="file" {...props} />
      </Button>
    </Stack>
  );
}
