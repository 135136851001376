import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

export default function PortfolioReportCard(props) {
  const { headerText, children, ...other } = props;

  return (
    <Box
      width={{
        xs: '100%',
        md: headerText === 'SCORE' ? 232 : 310,
      }}
      height={'100%'}
      display={'flex'} // Use flex display
      flexDirection={'column'} // Set the flex direction to column
      boxSizing={'border-box'}
    >
      <Card
        sx={{
          borderRadius: '4px',
          height: {
            xs: '100%',
            md:
              headerText === 'SUSTAINABILITY PERFORMANCE'
                ? '161px'
                : headerText === 'SUSTAINABILITY SNAPSHOT'
                ? '248px'
                : '425px',
          },
          // minHeight: { xs: 'auto', md: '425px' },
          // maxHeight: { xs: 'auto', md: '425px' },
          boxSizing: 'border-box',

          border: 1,
        }}
        {...other}
      >
        <CardHeader
          sx={{ bgcolor: 'primary.main', textAlign: 'center' }}
          subheader={
            <Typography variant="h7" fontWeight={1} color="white">
              {headerText}
            </Typography>
          }
        />

        <CardContent
          sx={{
            bgcolor: 'white',
            padding: 0,
            height: '100%',
            flex: 1,
          }}
        >
          {children}
        </CardContent>
      </Card>
    </Box>
  );
}
