import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { TabPanel } from './resources/TabPanel';
import InvestorProfile from './myclients/InvestorProfile';
import NewClient from './myclients/NewClient';
import RemoveClient from './myclients/RemoveClient';
import Typography from '@mui/material/Typography';

export default function FundPortfolioReport() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      // sx={{ flexGrow: 1, marginTop: '2%', marginLeft: '5%', marginRight: '2%' }}
      width="100%"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="secondary"
        right
      >
        <Tab
          sx={{
            px: {
              xs: '4px',
              md: '16px',
            },
          }}
          label={
            <Typography
              variant="h6"
              fontWeight={1}
              fontSize={{ xs: 14, sm: 15, md: 17, lg: 19, xl: 20 }}
            >
              Values profile
            </Typography>
          }
          disableRipple
        />
        <Tab
          sx={{
            px: {
              xs: '4px',
              md: '16px',
            },
          }}
          label={
            <Typography
              variant="h6"
              fontWeight={1}
              fontSize={{ xs: 14, sm: 15, md: 17, lg: 19, xl: 20 }}
            >
              New client
            </Typography>
          }
          disableRipple
        />
        <Tab
          sx={{
            px: {
              xs: '4px',
              md: '16px',
            },
          }}
          label={
            <Typography
              variant="h6"
              fontWeight={1}
              fontSize={{ xs: 14, sm: 15, md: 17, lg: 19, xl: 20 }}
            >
              Remove client
            </Typography>
          }
          disableRipple
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <InvestorProfile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NewClient />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RemoveClient />
      </TabPanel>
    </Box>
  );
}
