import * as React from 'react';

function SvgAlcohol(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 125"
      {...props}
    >
      <path d="M52.5 87.527V60.296c0-1.771 2.479-3.705 5.618-6.155 7.657-5.977 19.229-15.009 19.229-38.852 0-3.734-.251-7.478-.745-11.125A2.5 2.5 0 0074.124 2H25.876a2.5 2.5 0 00-2.478 2.164 83.187 83.187 0 00-.745 11.125c0 23.843 11.571 32.875 19.229 38.852 3.139 2.45 5.618 4.385 5.618 6.155v27.231c-16.32.338-16.334 3.984-16.334 5.223 0 1.299 0 5.25 18.834 5.25s18.834-3.951 18.834-5.25c0-1.238-.014-4.885-16.334-5.223zM28.092 7h43.816c.291 2.735.438 5.514.438 8.289 0 3.587-.286 6.784-.788 9.662-3.015.618-10.423 1.651-20.927-.131-11.211-1.902-18.524-.902-22.214-.001-.487-2.843-.765-5.998-.765-9.53.001-2.775.149-5.554.44-8.289zm16.867 43.199c-5.36-4.184-12.17-9.51-15.416-20.505 3.025-.744 9.686-1.737 20.253.056 4.693.797 8.818 1.074 12.288 1.074 3.387 0 6.148-.265 8.207-.568-3.309 10.619-9.98 15.831-15.25 19.943-1.905 1.487-3.688 2.879-5.041 4.374-1.354-1.495-3.136-2.886-5.041-4.374z" />
    </svg>
  );
}

export default SvgAlcohol;
