import * as React from "react";
import { Stack,Typography } from "@mui/material";



const Paragraph=(props)=>{
    const {children}=props
    return(
            
                    <Typography color={'white'} fontSize={9.2} textAlign={'center'}>
                            {children}
                    </Typography>
                         
    )
}



export default function ProfileText(props){
    const {ProfileType}=props;
   
    if(ProfileType==='STRONG-GLOBAL')
    {
        return (
        <Stack spacing={0.5} padding={1}>
                <Paragraph>
                    The STRONG GLOBAL type matches investors who are likely to express a high sensitivity to companies’ behavior regarding Environmental, Social and Governance aspects (ESGs factors).
                </Paragraph>
                <Paragraph>
                    Their sensitivity is rather constant across all ESG themes, therefore investment strategies or approaches that integrate ESG factors in a systematic manner could be of particular appeal to them.
                </Paragraph>
                <Paragraph>
                    They are also likely to appreciate regular reporting or communication concerning the alignment of their investments with their personal values.
                </Paragraph>
        </Stack>)
    }
    else if(ProfileType==='STRONG-THEMATIC'){
        return (
            <Stack spacing={0.5} padding={1}>
                    <Paragraph>
                        The STRONG THEMATIC type matches investors who generally express a fairly strong sensitivity to the behavior of companies regarding Environmental, Social and corporate’s Governance aspects (ESGs factors).
                    </Paragraph>
                    <Paragraph>
                        They are often more sensitive to certain ESG issues relative to others. Thematic investment strategies reflecting these differences could be of particular appeal to them.
                    </Paragraph>
                    <Paragraph>
                    They are also likely to appreciate regular reporting or communication on the sustainability of their investments, but this will be more effective if tailored to their sensitivities.
                    </Paragraph>
            </Stack>)
    }
    else if(ProfileType==='MODERATE-THEMATIC'){
        return(
        <Stack spacing={0.5} padding={1}>
                <Paragraph>
                The MODERATE THEMATIC type corresponds to investors who generally express reasonable sensitivity to the behavior of companies with respect to environmental, social, and corporate governance (ESG) issues.
                </Paragraph>
                <Paragraph>
                While they may appear to be relatively less concerned about sustainability, this is not necessarily the case. They are often simply more skeptical about mixing sustainability and finance and are potentially more mindful of greenwashing issues.
                </Paragraph>
                <Paragraph>
                This type of investor may nevertheless have strong sensitivities to specific ESG issues. Thematic investment strategies that reflect these distinctions may be of particular interest to them.
                </Paragraph>
                <Paragraph>
                The amount of reporting and communication regarding the sustainability of their investments needs to be adjusted on a case-by-case basis and will be more effective if tailored to their sensitivities.
                </Paragraph>
        </Stack>
        )
    }
    else if(ProfileType==='MODERATE-GLOBAL'){
        return (
            <Stack spacing={0.5} padding={1}>
                <Paragraph>
                MODERATE GLOBAL type matches investors who generally express a reasonable sensitivity to the behavior of companies regarding Environmental, Social and corporate’s Governance aspects (ESGs factors). 
                </Paragraph>
                <Paragraph>
                While they may appear to be relatively less concerned about sustainability, this is not necessarily the case. They are often simply more skeptical about mixing sustainability and finance and are potentially more mindful of greenwashing issues.
                </Paragraph>
                <Paragraph>
                This type of investors usually exhibits a rather constant sensitivity across all ESG themes.
                </Paragraph>
                <Paragraph>
                The amount of reporting and communication regarding the sustainability of their investments needs to be adjusted on a case by case basis.
                </Paragraph>
            </Stack>
        )
    }
    else{
        return ('')
    }
}