import * as React from 'react';

function SvgCoal(props) {
  return (
    <svg
      width="30"
      height="37"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 80"
      {...props}
    >
      <path d="M20 2a4.986 4.986 0 00-4.377 2.614A5.947 5.947 0 0013 4c-3.309 0-6 2.691-6 6 0 2.967 2.167 5.431 5 5.91V18h2v-2h3v-2h-4c-2.206 0-4-1.794-4-4s1.794-4 4-4a3.96 3.96 0 012.399.799A3.97 3.97 0 0117 10h2a5.94 5.94 0 00-1.742-4.219A2.998 2.998 0 0120 4c1.654 0 3 1.346 3 3h2c0-2.757-2.243-5-5-5zm-3.98 3.973L16 6l.02-.027zM58 2c-1.326 0-2.52.643-3.251 1.684A5.838 5.838 0 0052 3c-3.009 0-5.5 2.228-5.928 5.12C44.315 8.541 43 10.115 43 12h2c0-1.103.897-2 2-2s2 .897 2 2h2c0-1.826-1.237-3.352-2.911-3.83A4.006 4.006 0 0152 5c.908 0 1.775.316 2.509.914a.999.999 0 001.589-.486A1.982 1.982 0 0158 4c1.103 0 2 .897 2 2h2c0-2.206-1.794-4-4-4zM50.8 25.883a6.16 6.16 0 001.19.116 5.997 5.997 0 005.926-4.998c.917.02 1.795-.298 2.641-.958C61.461 19.336 62 18.199 62 17h-2c0 .588-.252 1.136-.673 1.465-.873.681-1.502.587-2.004.405a1.001 1.001 0 00-1.341.978c.001.035.013.117.018.152 0 1.2-.532 2.326-1.459 3.089-.941.774-2.134 1.069-3.357.831C49.339 23.56 48 21.821 48 19.785V19.5c0-1.93-1.57-3.5-3.5-3.5S41 17.57 41 19.5V22h-4v-5h-2v5h-2a1 1 0 00-1 1v3.628c0 2.815-.368 5.62-1.087 8.317l-1.99 6.884-1.83-6.864A32.345 32.345 0 0126 26.628V21a1 1 0 00-1-1H11a1 1 0 00-1 1v5.628c0 2.815-.368 5.62-1.093 8.337L2.034 60.742A1.001 1.001 0 003 62h58a1 1 0 001-1v-4a.997.997 0 00-.293-.707l-5-5A.997.997 0 0056 51h-4.272l-4.635-16.034A32.358 32.358 0 0146 26.628V23a1 1 0 00-1-1h-2v-2.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v.285c0 2.99 2.019 5.554 4.8 6.098zM4.302 60l6.538-24.519c.393-1.473.684-2.971.878-4.481H21v-2h-9.086c.046-.665.067-1.332.074-2H19v-2h-7v-3h12v4.628c0 2.989.39 5.967 1.16 8.853l2.686 10.073-.048.167.101.029L31.698 60H4.302zm27.6-7h21.684l-3 3H43v2h7v2H33.768l-1.866-7zM52 60v-2h8v2h-8zm6.586-4h-5.172L56 53.414 58.586 56zm-13.42-20.499L49.646 51H31.368l-1.447-5.425 2.918-10.094c.306-1.147.535-2.311.72-3.481H42v-2h-8.184c.066-.665.113-1.332.139-2H40v-2h-6v-2h10v2.628c0 2.989.39 5.968 1.166 8.873zM23 12h2c0-.874.276-1.704.799-2.4L24.2 8.399A5.95 5.95 0 0023 12z" />
      <path d="M37.86 12.907a3.945 3.945 0 01-3.33-.788 1.003 1.003 0 00-1.606.603 3.985 3.985 0 01-2.372 2.963c-.609.259-1.371.083-1.892-.438l-1.126-1.126A3.796 3.796 0 0024.829 13 3.832 3.832 0 0021 16.829V19h2v-2.171a1.83 1.83 0 013.122-1.293l1.126 1.126c1.092 1.092 2.733 1.439 4.087.865a5.98 5.98 0 003.144-3.096 5.877 5.877 0 003.802.431c2.298-.495 4.146-2.361 4.598-4.645a6.033 6.033 0 00-1.241-5.023 5.991 5.991 0 00-10.117 1.375A5.84 5.84 0 0029 6v2c.889 0 1.743.305 2.469.881a1 1 0 001.605-.603 3.993 3.993 0 017.019-1.815c.775.943 1.068 2.138.825 3.365-.3 1.512-1.528 2.75-3.058 3.079z" />
    </svg>
  );
}

export default SvgCoal;
