import * as React from 'react';
import ReactECharts from 'echarts-for-react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';

const create_tooltip = (xLabel, yLabel) => {
  return {
    tooltip: {
      trigger: 'item',
      formatter: `${yLabel} - ${xLabel}`,
    },
  };
};

const create_series = (datapoints) => {
  return {
    series: [
      {
        Name: 'Investor Profile',
        type: 'scatter',
        symbolSize: 25,
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          borderColor: 'rgb(0,56,101)',
          borderWidth: '3',
        },
        color: 'rgb(0,56,101,.5)',
        data: [datapoints],
      },
    ],
  };
};

const base = {
  grid: {
    left: '30',
    right: '20',
    bottom: '120',
    top: '10',
  },

  xAxis: {
    name: 'Global vs. Thematic',
    nameLocation: 'middle',
    nameGap: 10,
    splitNumber: '2',
    min: '0',
    max: '100',
    splitLine: {
      lineStyle: {
        color: 'black',
      },
    },
    axisLabel: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },

  yAxis: {
    name: 'Moderate vs. Strong',
    nameLocation: 'middle',
    nameGap: 10,
    splitNumber: '2',
    min: '0',
    max: '100',
    splitLine: {
      lineStyle: {
        color: 'black',
      },
    },
    axisLabel: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
};

const AnalyticsCard = (props) => {
  const { header, height, children, ...other } = props;

  return (
    <Card
      sx={{
        borderRadius: '8px',
        height: height,
        border: '2px solid #003865',
        width: 1,
      }}
      {...other}
    >
      <CardHeader
        sx={{ bgcolor: 'primary.main', textAlign: 'center', height: 20 }}
        subheader={
          <Typography variant="h7" fontWeight={1} color="white">
            {header}
          </Typography>
        }
      />

      <CardContent sx={{ bgcolor: 'white', padding: 0 }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default function GlobalPositioningDoc(props) {
  const { x_coord, y_coord, cls_sdev, cls_sum } = props;

  const tooltip = create_tooltip(cls_sdev, cls_sum);
  const series = create_series([x_coord, y_coord]);
  const option = { ...base, ...tooltip, ...series };

  return (
    <AnalyticsCard header="GLOBAL POSITIONING" height={240} fullWidth>
      <Box width="100%" maxWidth="400px" mx={'auto'}>
        <ReactECharts option={option} />
      </Box>
    </AnalyticsCard>
  );
}
