import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import NorsiaAppBar from './Header';
import { TabPanel } from './tabitems/resources/TabPanel';
import PortfolioReport from './tabitems/PortfolioReport';
import MyClients from './tabitems/Myclients';

import { CustomSvgIcon } from './icons/IconManager';
import { useTheme } from '@emotion/react';
import { Collapse, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FundsScreener from './tabitems/FundsScreener';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: 40,

    backgroundColor: '#fff',
  },
  backgroundColor: '#003865',

  marginRight: 0,
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0),
    justifyContent: 'left',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#F39200',
    },
    '&.Mui-disabled': {
      color: 'gray',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    minWidth: 300,
  })
);

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const tabItems = [
  { text: 'My Clients', iconName: 'MyClients', index: 0, disabled: false },
  { text: 'My Strategies', iconName: 'Strategies', index: 1, disabled: true },
  {
    text: 'Companies Screener',
    iconName: 'CompaniesScreener',
    index: 2,
    disabled: true,
  },
  {
    text: 'Funds Screener (beta)',
    iconName: 'FundsScreener',
    index: 3,
    disabled: false,
  },
  {
    text: 'Portfolio Report',
    iconName: 'PortfolioReport',
    index: 4,
    disabled: false,
  },
  { text: 'Settings', iconName: 'Settings', index: 5, disabled: true },
];

export default function ProtectedLandingPage(props) {
  //const location=useLocation();

  const [hide, setHide] = React.useState(true);
  const theme = useTheme();
  const shouldDisplay = useMediaQuery(theme.breakpoints.up('md'));
  const [showBox, setShowBox] = React.useState(true);

  React.useEffect(() => {
    setShowBox(!hide || shouldDisplay);
  }, [hide, shouldDisplay]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setHide(!hide);
  };

  return (
    <Box height="100vh" display={'flex'} flexDirection={'column'}>
      <NorsiaAppBar showBox={showBox} setHide={setHide} hide={hide} />
      <Box
        sx={{
          border: 2,
          margin: 1,
          marginTop: 10,
          borderRadius: 1.5,
          borderColor: 'primary.main',
          flexGrow: 1,
          display: 'flex',
          flex: 1,
          position: 'relative',
        }}
      >
        <Box
          position={{
            xs: 'fixed',
            md: 'relative',
          }}
          backgroundColor="#003865"
          zIndex={98}
          sx={{
            display: 'block',
            transition: 'width 300ms ease-in-out',
            width: {
              xs: showBox ? '100%' : '0',
              sm: showBox ? '50%' : '0',
              md: '360px',
            },
            top: {
              xs: 60,
              md: 0,
            },
            left: 0,
            borderTopRightRadius: {
              xs: '4px',
              md: '0px',
            },
            // width: showBox ? '100%' : '0',
            overflow: 'hidden',
            height: '100%',
          }}
          // sx={{
          //   display: !hide || shouldDisplay ? 'block' : 'none',
          // }}
        >
          <StyledTabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
          >
            {tabItems.map((item) => (
              <StyledTab
                icon={
                  <CustomSvgIcon
                    name={item.iconName}
                    color="secondary"
                    sx={{ fontSize: { xs: 45, sm: 30 } }}
                    viewBox="0 0 90 80"
                  />
                }
                iconPosition="start"
                label={
                  <>
                    {item.text.replace('(beta)', '')} {/* Main text */}
                    <span style={{ fontSize: '12px', marginLeft: '2px' }}>
                      {/* Smaller "(beta)" text */}
                      {item.text.includes('(beta)') ? '(beta)' : ''}
                    </span>
                  </>
                }
                disabled={item.disabled}
                {...a11yProps(item.index)}
              />
            ))}
          </StyledTabs>
        </Box>

        <TabPanel value={value} index={0}>
          <MyClients />
        </TabPanel>
        <TabPanel value={value} index={1}>
          My Strategies
        </TabPanel>
        <TabPanel value={value} index={2}>
          Companies Screener
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FundsScreener setValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PortfolioReport />
        </TabPanel>
        <TabPanel value={value} index={5}>
          Settings
        </TabPanel>
      </Box>
    </Box>
  );
}
