import * as React from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { CustomSvgIcon } from '../../icons/IconManager';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  nonSelectable: {
    userSelect: 'none',
  },

  customTooltip: {
    fontSize: '14px',
    color: '#fff',
    borderRadius: '4px',
    padding: '8px 12px',
    margin: 0,
  },
}));

export default function PortfolioReportExclusionIcons(props) {
  const { percent, iconName } = props;

  const classes = useStyles();
  return (
    <Tooltip title={iconName} classes={{ tooltip: classes.customTooltip }}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        width="50px"
        height="50px"
        mx={'auto'}
        mt={'-5px'}
      >
        <CustomSvgIcon name={iconName} color="primary" viewBox="0 0 150 90" />

        <Typography fontSize={10} color={'primary'}>{`${
          percent != null ? `${percent}%` : 'N/A'
        }`}</Typography>
      </Stack>
    </Tooltip>
  );
}
