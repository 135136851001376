import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import { Slider } from '@mui/material';
import { Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const Widget = styled(Avatar)(({ theme }) => ({
  background: 'white',
  border: '3px solid',
  borderColor: theme.palette.primary.main,
  height: 30,
  width: 30,
}));

const WidgetText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 10,
  fontWeight: 'bold',
  margin: 'auto',
}));

const MetricText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const AvatarSlider = (props) => {
  const { metricLetter, metricValue } = props;

  return (
    <Grid
      container
      direction="column"
      alignItems={'center'}
      justifyContent={'center'}
      gap={0}
      marginTop={4}
    >
      <Widget>
        <WidgetText>{metricValue}</WidgetText>
      </Widget>

      <Slider
        value={metricValue}
        orientation="vertical"
        sx={{
          pointerEvents: 'none',
          mx: 'auto',
          marginTop: 1,
          height: 140,
          width: 10,
          '& .MuiSlider-thumb': {
            background: '#003865',
            width: 0,
            height: 0,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-rail': {
            backgroundColor: 'white',
            border: '2px solid #012038',
            width: 10,

            height: 135,
          },
          '& .MuiSlider-track': {
            width: 10,
            height: 'auto',
            backgroundColor: '#003865',
          },
        }}
      />

      <MetricText>{metricLetter}</MetricText>
    </Grid>
  );
};

export default function ScoreMetricsBottomDoc(props) {
  const { metrics } = props;

  if (metrics === undefined) return <div>TODO:implement error</div>;

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      maxWidth={'200px'}
      mx={'auto'}
      // marginLeft={5}
      // marginRight={0}
    >
      {metrics.map((metric) => (
        <AvatarSlider metricValue={metric.value} metricLetter={metric.letter} />
      ))}
    </Stack>
  );
}
